export default{
	//自定义时间过滤器 年月
	monthMethod(originVal){
		if(originVal==null||originVal ===''){
				return '--';
			}
		 const date = new Date(originVal)
		 var Y = date.getFullYear() + '-';
		 var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) ;
		return Y+M;
	},
	//自定义时间过滤器 年月日
	dateMethod(originVal){
		if(originVal==null||originVal ===''){
				return '--';
			}
		 const date = new Date(originVal)
		 var Y = date.getFullYear() + '-';
		 var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
		 var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate());
		return Y+M+D;
	},
	//自定义时间过滤器 具体时分秒
	dateFormat(originVal){
		if(originVal==null||originVal ===''){
				return '--';
			}
		 const date = new Date(originVal)
		 var Y = date.getFullYear() + '-';
		 var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
		 var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
		 var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
		 var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
		 var s = date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds(); 
		return Y+M+D+h+m+s;
	},
	//自定义时间过滤器 具体时分秒
	dateTimeFormat(originVal){
		if(originVal==null||originVal ===''){
				return '--';
			}
		 const date = new Date(originVal)
		 var Y = date.getFullYear() + '-';
		 var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
		 var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
		 var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
		 var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
		 var s = date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds(); 
		return M+D+h+m+s;
	}
}