import Vue from 'vue'
import App from './App.vue'
import router from '@/router'; //引入自定义路由
import axios from 'axios'
Vue.prototype.$axios = axios; //引入使用axios在此之前安装axios
import ElementUI from 'element-ui'; //引入使用ElementUI
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css'; //引入col布局断点隐藏css
Vue.use(ElementUI);
import '@/assets/css/common.scss'; //引入公共的common.scss样式
import '@/assets/css/animate.css'; //引入公共的animate.css样式
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import filters from "./assets/js/filter.js" //过滤器
Vue.prototype.$filters = filters;
Vue.config.productionTip = false;
// 测试环境
// axios.defaults.baseURL = 'https://www.canhelpgenomics.cn';
// Vue.prototype.imgUrl = 'https://www.canhelpgenomics.cn'; 
// Vue.prototype.windowUrl = 'https://v2.canhelpgenomics.cn'; 
// Vue.prototype.wsUrl='wss://www.canhelpgenomics.cn/api/ws/customer/';

// 正式环境
axios.defaults.baseURL = 'https://www.canhelpdx.com';
Vue.prototype.imgUrl = 'https://www.canhelpdx.com'; 
Vue.prototype.windowUrl = 'https://www.canhelpgenomics.com'; 
Vue.prototype.wsUrl='wss://www.canhelpdx.com/api/ws/customer/'

// 添加请求拦截器
axios.interceptors.request.use(function(config) {
	if (window.localStorage.getItem('access-token')) {
		config.headers.Authorization = window.localStorage.getItem('access-token');
	}
	return config
}, function(error) {
	return Promise.reject(error)
});

// 添加响应拦截器
axios.interceptors.response.use(function(response) {
	return response
}, function(error) {
	return Promise.reject(error)
});
// 解决导航当前路由报错问题
const originalPush = router.push
router.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const i18n = new VueI18n({
	locale: 'zh', //默认显示的语言 
	messages: {
		zh: require('./components/locales/zh.json'), //引入语言文件
		en: require('./components/locales/en.json'),
	}
})
new Vue({
	render: h => h(App),
	router,
	i18n,
}).$mount('#app')
