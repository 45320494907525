<template>
	<main class="myNews">
		<Nav />
		<transition name="slide-top">
			<article v-if="show" class="infoNews">
				<div class="newsTtile" v-if="$i18n.locale=='zh'">
					<h3>{{title}}</h3>
					<p v-show="source">来源：{{source}}</p>
					<p>发布时间&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{date}}</p>
				</div>
				<div class="newsTtile" v-if="$i18n.locale=='en'">
					<h3>{{entitle}}</h3>
					<p v-show="ensource">来源：{{ensource}}</p>
					<p>发布时间&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{endate}}</p>
				</div>
				<div class="newsContent" v-html="$i18n.locale=='zh'?newsContent:ennewsContent"></div>
				<section class="newsFooter" v-if="$i18n.locale=='zh'">
					<div class="preNew">
						<span v-show="prev.id" @click="rout(prev.id)">上一条：{{prev.title}}</span>
						<span v-show="next.id" @click="rout(next.id)">下一条：{{next.title}}</span>
					</div>
					<div class="backNew">
						<p @click="back">返回</p>
					</div>
				</section>
				<section class="newsFooter" v-if="$i18n.locale=='en'">
					<div class="preNew">
						<span v-show="enprev.id" @click="rout(enprev.id)">上一条：{{enprev.title}}</span>
						<span v-show="ennext.id" @click="rout(ennext.id)">下一条：{{ennext.title}}</span>
					</div>
					<div class="backNew">
						<p @click="back">返回</p>
					</div>
				</section>
			</article>
		</transition>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				title: "",
				date: "",
				source: "",
				prev: {},
				next: {},
				newsContent: "",
				key: "", //1可帮信息 2行业动态 4科学研究
				show:false,
				// 英文赋值
				ennewsContent: "",
				entitle: "",
				endate: "",
				ensource: "",
				enprev: {},
				ennext: {},
			}
		},
		watch: {
			'$route' (to, from) {   //监听路由是否变化
				if(to.query.id != from.query.id && to.query.id != null){  //pid不为空才请求数据
					this.getNewsinfo(to.query.id); //调接口，请求数据
				}
			}
		},
		mounted() {
			var that = this;
			that.key = that.$route.query ? that.$route.query.key : "";
			const id = that.$route.query ? that.$route.query.id : "";
			that.getNewsinfo(id);
			that.show=true;
		},
		methods: {
			getNewsinfo(id) {
				var that = this;
				that.$axios
					.get("/api/news/detail/"+id)
					.then(function(res) {
						that.newsContent=res.data.data.news.content;
						that.title=res.data.data.news.title;
						that.date=res.data.data.news.date;
						that.source=res.data.data.news.source ? res.data.data.news.source : "";
						that.prev=res.data.data.prev ? res.data.data.prev : {};
						that.next=res.data.data.next ? res.data.data.next : {};
					})
					.catch(function(error) {});
				// that.$axios
				// 	.get("/api/engNews/detail/"+id)
				// 	.then(function(res) {
				// 		that.ennewsContent=res.data.data.news.content;
				// 		that.entitle=res.data.data.news.title;
				// 		that.endate=res.data.data.news.date;
				// 		that.ensource=res.data.data.news.source ? res.data.data.news.source : "";
				// 		that.enprev=res.data.data.prev ? res.data.data.prev : {};
				// 		that.ennext=res.data.data.next ? res.data.data.next : {};
				// 	})
				// 	.catch(function(error) {});
			},
			// 跳转详情内容
			rout(id) {
				var that=this;
				that.key = that.$route.query ? that.$route.query.key : "";
				setTimeout(function() {
					//在页码改变的时候要将参数以query的形式传到路径上
					that.$router.replace({
						path: '/newsinfo',
						query: {
							id: id,
							key:that.key
						}
					})
				}, 50);
			},
			// 返回父界面
			back() {
				var that = this;
				that.$router.go(-1); //返回上一个路由 
			},
		},

	}
</script>

<style lang="scss" scoped>
	.myNews {
		background: #F5F5F5;
		padding-bottom: 3% !important;
	}
	.infoNews{
		margin: 5% 10% 0 !important;
	}
	// @media only screen and (min-width: 1200px){
	// 	.newsContent{
	// 		img{
	// 			width: 50% !important;
	// 			height: auto !important;
	// 		}
	// 	}
	// }
	// @media only screen and (max-width: 1200px){
	// 	.newsContent{
	// 		img{
	// 			width: 60% !important;
	// 			height: auto !important;
	// 		}
	// 	}
	// }
	// @media only screen and (max-width: 992px){
	// 	.newsContent{
	// 		img{
	// 			width: 100% !important;
	// 			height: auto !important;
	// 		}
	// 	}
	// }
</style>
