<template>
	<main class="myAbout">
		<Nav />
		<transition name="slide-top">
			<div v-if="show" class="partnerDiv">
				<h1>{{$t('about.partner.h1')}}</h1>
				<p class="techP">{{$t('about.partner.p1')}}<span>{{$t('about.partner.p2')}}</span><span>{{$t('about.partner.p3')}}</span></p>
				<div class="partnerBox">
					<el-row>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/bjzl.jpg" alt="北京肿瘤医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/dnzl.jpg" alt="东南大学附属中大医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/fd.jpg" alt="复旦大学"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/fdzl.jpg" alt="复旦大学附属肿瘤医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/fdsh.jpg" alt="复旦大学上海医学院"/>
						</el-col>
						
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/gz.jpg" alt="广州奥咨达医疗器械技术股份有限公司"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/jn.jpg" alt="江南大学附属医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/js.jpg" alt="江苏省人民医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/jsz.jpg" alt="江苏省中医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/jszl.jpg" alt="江苏省肿瘤医院"/>
						</el-col>
						
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/nj.jpg" alt="南京鼓楼医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/nt.jpg" alt="南通市肿瘤医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/smf.jpg" alt="赛默飞世尔"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/shdx.jpg" alt="上海交通大学医学院附属瑞金医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/sh.jpg" alt="上海市第一人民医院"/>
						</el-col>
						
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/sz.jpg" alt="苏州元禾原点"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/tj.jpg" alt="同济大学"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/xz.jpg" alt="徐州医科大学"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/zj2.jpg" alt="浙江大学医学院附属第二医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/zj1.jpg" alt="浙江大学医学院附属第一医院"/>
						</el-col>
						
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/zjchildren.jpg" alt="浙江大学医学院附属儿童医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/zjsyf.jpg" alt="浙江大学医学院附属邵逸夫医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/zj.jpg" alt="浙江省人民医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/zjzl.jpg" alt="浙江省肿瘤医院"/>
						</el-col>
						<el-col :xs="12" :sm="8" :md="6" :lg="{span:'4-8'}">
							<img src="../../assets/images/partner/china.jpg" alt="中国研究型医院协会"/>
						</el-col>
						
					</el-row>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				show:false
			}
		},
		mounted() {
			var that=this;
			that.show=true;
		}
	}
</script>

<style lang="scss" scoped>
	@media only screen and (max-width:768px) {
		.el-col:nth-child(2n){
			border-right: 1px solid rgba(255,255,255,1) !important;
		}
		.el-col:nth-child(25){
			border-bottom: 1px solid rgba(255,255,255,1) !important;
		}
	}
	@media only screen and (min-width:768px) {
		.el-col:nth-child(3n){
			border-right: 1px solid rgba(255,255,255,1) !important;
		}
		.el-col:nth-child(23),.el-col:nth-child(24),.el-col:nth-child(25){
			border-bottom: 1px solid rgba(255,255,255,1) !important;
		}
	}
	@media only screen and (min-width:992px) {
		.el-col:nth-child(3n){
			border-right: 1px solid rgba(204,204,204,0.5) !important;
		}
		.el-col:nth-child(4n){
			border-right: 1px solid rgba(255,255,255,1) !important;
		}
		.el-col:nth-child(22),.el-col:nth-child(23),.el-col:nth-child(24),.el-col:nth-child(25){
			border-bottom: 1px solid rgba(255,255,255,1) !important;
		}
	}
	@media only screen and (min-width:1200px) {
	    .el-col-lg-4-8 {
	        width: 20%
	    }
		.el-col:nth-child(4n){
			border-right: 1px solid rgba(204,204,204,0.5) !important;
		}
		.el-col:nth-child(5n){
			border-right: 1px solid rgba(255,255,255,1) !important;
		}
		.el-col:nth-child(21),.el-col:nth-child(22),.el-col:nth-child(23),.el-col:nth-child(24),.el-col:nth-child(25){
			border-bottom: 1px solid rgba(255,255,255,1) !important;
		}
	}
</style>
