<template>
	<nav class="myNav">
		<img v-show="navname1== $t('header.menu.index2')" src="../assets/images/banner_product.jpg">
		<img v-show="navname1== $t('header.menu.index3')" src="../assets/images/banner_tech.jpg">
		<img v-show="navname1== $t('header.menu.index4')" src="../assets/images/banner_news.jpg">
		<img v-show="navname1== $t('header.menu.index5')" src="../assets/images/banner_about.jpg">
		<img v-show="navname2== $t('header.menu.index61')" src="../assets/images/banner_job.jpg">
		<img v-show="navname2== $t('header.menu.index62')" src="../assets/images/banner_recruit.jpg">
		<transition name="slide-top">
			<div v-if="show" class="myNavDiv">
				<div class="myNuxtLink myNuxtLink0">
					<router-link :to="{ name: 'index'}">
						<i class="el-icon-s-home"></i>
						{{$t('header.menu.index1')}}
					</router-link>
				</div>
				<!-- 循环页面跳转标签 -->
				<div class="myNuxtLink myNuxtLink1" v-show="navname2">
					<router-link v-if="navname1== $t('header.menu.index2')" :to="{ name: 'product'}">
						{{navname1}}
					</router-link>
					<a v-else>
						{{navname1}}
					</a>
				</div>
				<!-- 没有name2的情况下 -->
				<div class="myNuxtLink" v-show="!navname2">
					<router-link v-if="navname1== $t('header.menu.index2')" :to="{ name: 'product'}">
						{{navname1}}
					</router-link>
					<a v-else>
						{{navname1}}
					</a>
				</div>
				<div class="myNuxtLink myNuxtLink2" v-show="navname2">
					<a>
						{{navname2}}
					</a>
				</div>
			</div>
		</transition>
	</nav>
</template>

<script>
	export default {
		data() {
			return {
				navname1: '',
				navname2: '',
				key: "", //1可帮信息 2行业动态 4科学研究
				show: false
			}
		},
		watch: {
		    '$i18n.locale'(val, newval) {
			    var that=this;
		        if (val != newval) {
			        that.routeMethod();
		       	}
		    }
		},
		created() {
			var that = this;
			that.key = that.$route.query ? that.$route.query.key : "";
			that.routeMethod();
		},
		mounted() {
			var that = this;
			that.show = true;
		},
		methods: {
			// 根据路由判断标签内容
			routeMethod() {
				var that = this;
				switch (that.$route.name) {
					case 'product':
						that.navname1 = that.$t('header.menu.index2');
						that.navname2 = "";
						break;
					case 'product-panca':
						that.navname1 = that.$t('header.menu.index2');
						that.navname2 = "Canhelp-Origin";
						break;
					case 'product-ubca':
						that.navname1 = that.$t('header.menu.index2');
						that.navname2 = "Canhelp-UriBLAD";
						break;
					case 'product-kidney':
						that.navname1 = that.$t('header.menu.index2');
						that.navname2 = "Canhelp-Kidney";
						break;
					case 'product-lung':
						that.navname1 = that.$t('header.menu.index2');
						that.navname2 = "Canhelp-Lung";
						break;
					case 'product-mb':
						that.navname1 = that.$t('header.menu.index2');
						that.navname2 = "Canhelp-MB";
						break;
					case 'product-coo':
						that.navname1 = that.$t('header.menu.index2');
						that.navname2 = "Canhelp-COO";
						break;
					case 'product-cases':
						that.navname1 = that.$t('header.menu.index2');
						that.navname2 = "案例分析";
						break;
					case 'product-pancaIntro':
						that.navname1 = that.$t('header.menu.index2');
						that.navname2 = "panCA";
						break;
					case 'technology-support':
						that.navname1 = that.$t('header.menu.index3');
						that.navname2 = that.$t('header.menu.index31');
						break;
					case 'technology-requirements':
						that.navname1 = that.$t('header.menu.index3');
						that.navname2 = that.$t('header.menu.index32');
						break;
					case 'technology-dynamic':
						that.navname1 = that.$t('header.menu.index3');
						that.navname2 = that.$t('header.menu.index33');
						break;
					case 'technology-achievements':
						that.navname1 = that.$t('header.menu.index3');
						that.navname2 = that.$t('header.menu.index34');
						break;
					case 'technology-pipeline':
						that.navname1 = that.$t('header.menu.index3');
						that.navname2 = that.$t('header.menu.index35');
						break;
					case 'technology-service':
						that.navname1 = that.$t('header.menu.index3');
						that.navname2 = that.$t('header.menu.index36');
						break;
					case 'technology-committee':
						that.navname1 = that.$t('header.menu.index3');
						that.navname2 = "科学委员会";
						break;
					case 'news-company':
						that.navname1 = that.$t('header.menu.index4');
						that.navname2 = that.$t('header.menu.index41');
						break;
					case 'news-industry':
						that.navname1 = that.$t('header.menu.index4');
						that.navname2 = that.$t('header.menu.index42');
						break;
					case 'news-research':
						that.navname1 = that.$t('header.menu.index4');
						that.navname2 = that.$t('header.menu.index43');
						break;
					case 'newsinfo':
						that.navname1 = that.$t('header.menu.index4');
						that.navname2 = that.key == 1 ? that.$t('header.menu.index41') : that.key == 2 ? that.$t(
							'header.menu.index42') : that.key == 4 ? that.$t('header.menu.index43') : that.$t(
							'header.menu.index41');
						break;
					case 'about-introduction':
						that.navname1 = that.$t('header.menu.index5');
						that.navname2 = that.$t('header.menu.index51');
						break;
					case 'about-partner':
						that.navname1 = that.$t('header.menu.index5');
						that.navname2 = that.$t('header.menu.index52');
						break;
					case 'about-culture':
						that.navname1 = that.$t('header.menu.index5');
						that.navname2 = that.$t('header.menu.index53');
						break;
					case 'about-contact':
						that.navname1 = that.$t('header.menu.index5');
						that.navname2 = that.$t('header.menu.index54');
						break;
					case 'about-institution':
						that.navname1 = that.$t('header.menu.index5');
						that.navname2 = "投资机构";
						break;
					case 'about-team':
						that.navname1 = that.$t('header.menu.index5');
						that.navname2 = "管理团队";
						break;
					case 'about-privacypolicy':
						that.navname1 = that.$t('header.menu.index5');
						that.navname2 = that.$t('header.menu.index55');
						break;
					case 'join-job':
						that.navname1 = that.$t('header.menu.index6');
						that.navname2 = that.$t('header.menu.index61');
						break;
					case 'join-recruitment':
						that.navname1 = that.$t('header.menu.index6');
						that.navname2 = that.$t('header.menu.index62');
						break;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.myNav {
		width: 100%;
		height: 14rem;
		position: relative;
		margin-top: 4rem;

		img {
			width: 100%;
			height: 14rem;
			object-fit: cover;
		}

		.myNavDiv {
			width: 80%;
			margin: 0 auto;
			position: absolute;
			top: 12rem;
			left: 0;
			right: 0;
			background-color: #0472BF;
			z-index: 1;
			font-size: 0.7rem;
			height: 2rem;
			line-height: 2rem;
			display: flex;

			.myNuxtLink {
				min-width: 7rem;
				display: flex;

				i {
					color: #FFFFFF;
					font-size: 0.8rem;
					font-weight: lighter;
					vertical-align: middle;
				}

				a {
					color: #FFFFFF;
					text-decoration: none;
					margin: 0 auto;
				}

			}

			.myNuxtLink0 {
				background: url(../assets/images/2_03.png) right no-repeat !important;
				background-size: cover;
			}

			.myNuxtLink1 {
				background: url(../assets/images/green_arrow_l.png) right no-repeat !important;
				background-size: cover;
			}

			.myNuxtLink2 {
				background: url(../assets/images/2_04.png) right no-repeat !important;
				background-size: contain;
			}

		}
	}
</style>
