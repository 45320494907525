<template>
	<main class="myProduct">
		<Nav />
		<!-- 主要内容 -->
		<div class="productMain">
			<!-- 左侧菜单 -->
			<product-menu class="leftDetail"></product-menu>
			<!-- 右侧内容 -->
			<product-detail class="rightDetail" :detailData="detailData"></product-detail>
		</div>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	import productMenu from "./productMenu.vue";
	import productDetail from "./productDetail.vue";
	export default {
		name: 'product-panca',
		components: {
			Nav,
			productMenu,
			productDetail
		},
		data() {
			return {
				detailData:{
					name:'Canhelp-Origin肿瘤组织起源基因检测',
					indication:['原发灶不明肿瘤','多原发肿瘤'],
					background:'原发灶不明恶性肿瘤（Carcinomas of Unknown Primary，CUP）是一类确诊为转移性，但经过详尽的检测和评估仍然不能确定原发灶的恶性肿瘤。在世界范围内，CUP约占所有新发癌症的3%~5%，是全球第八大最常见的癌症，也是死亡率第四高的癌症。目前，对于CUP患者尚无标准的治疗方法，一般采用广谱的化疗方案(如紫杉醇、卡铂等)，但是疗效不佳，预后较差，中位生存期低于11个月，2年生存率低于20%。若能确定其原发灶，根据其原发灶进行针对性的治疗，则患者的预后将获得改善。',
					description:'Canhelp-Origin是国内首个且唯一获批国家药品监督管理局（National Medical Products Administration，NMPA）三类医疗器械注册证的组织溯源检测。肿瘤组织起源基因检测适用于分化程度较差或疑似转移的肿瘤组织，可同时检测 90 个肿瘤特征基因的表达模式，结合人工智能算法，自动判别肿瘤的类型和起源。产品囊括乳腺癌、肺癌、肝癌、肠癌、胃癌等 21 种肿瘤类型，覆盖超过90%的实体肿瘤患者。',
					advantage:[{
						img:require('../../assets/images/productDetail/panca/icon-1.png'),
						title:'权威认证',
						text:'已获得NMPA第三类医疗器械注册证和欧盟的CE认证。'
					},{
						img:require('../../assets/images/productDetail/panca/icon-2.png'),
						title:'覆盖全面',
						text:'检测21种实体肿瘤类型，囊括了90%的实体肿瘤患者。'
					},{
						img:require('../../assets/images/productDetail/panca/icon-3.png'),
						title:'快速精准',
						text:'3小时完成PCR和数据分析；经大样本临床验证，整体准确率超90%。'
					},{
						img:require('../../assets/images/productDetail/panca/icon-4.png'),
						title:'简便客观',
						text:'人工智能分析软件，自动数据分析，结果不受主观判断干扰。'
					}],
					people:['低分化或未分化肿瘤患者','组织量少，多个标记检测受限的肿瘤患者','病理诊断为鳞状细胞癌的肿瘤患者','免疫组化无法明确组织起源的肿瘤患者','既往肿瘤病史，需鉴定第二原发或复发转移的肿瘤患者']
				},
			}
		},
		mounted() {
			var that = this;
			that.show = true;
		}
	}
</script>

<style lang="scss" scoped>
	.myProduct{
		.productMain {
			padding: 2% 15% 5% !important;
			display: flex;
		}
		.leftDetail{
			width: 200px;
			flex-shrink: 0;
			margin-right: 20px;
		}
		.rightDetail{
			width: 100%;
		}
	}
	@media (max-width:1024px){
		.leftDetail{
			display:none !important;
		}
	}
</style>