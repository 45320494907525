import VueRouter from 'vue-router' //安装引入vue-router
import Vue from 'vue'
import Header from "../components/header.vue" //顶部导航组件
import Footer from "../components/footer.vue" //底部版权等信息组件
import index from "../components/index.vue" //首页vue
// 产品中心
import panca from "../components/product/panca.vue"
import ubca from "../components/product/ubca.vue"
import coo from "../components/product/coo.vue"
import kidney from "../components/product/kidney.vue"
import lung from "../components/product/lung.vue"
import mb from "../components/product/mb.vue"
// 产品中心，案例，介绍菜单没有相应导航
import product from "../components/product/index.vue"
import cases from "../components/product/cases.vue"
import pancaIntro from "../components/product/pancaIntro.vue"

// 技术支持
import support from "../components/technology/support.vue"
import requirements from "../components/technology/requirements.vue"
import dynamic from "../components/technology/dynamic.vue"
import achievements from "../components/technology/achievements.vue"
import pipeline from "../components/technology/pipeline.vue"
import service from "../components/technology/service.vue"

// 新闻动态
import company from "../components/news/company.vue"
import industry from "../components/news/industry.vue"
import research from "../components/news/research.vue"
import newsinfo from "../components/news/newsinfo.vue"

// 关于可帮
import introduction from "../components/about/introduction.vue"
import partner from "../components/about/partner.vue"
import culture from "../components/about/culture.vue"
import contact from "../components/about/contact.vue"
import institution from "../components/about/institution.vue"
import team from "../components/about/team.vue"
import privacypolicy from "../components/about/privacypolicy.vue"

// 加入我们
import job from "../components/join/job.vue"
import recruitment from "../components/join/recruitment.vue"

/**
 * 安装插件
 */
Vue.use(VueRouter)

/**
 * 创建路由对象
 */
const routes = [{
		path: '/',
		name: 'index',
		component: index,
		meta:{
			menuId:'1'
		}
	},
	// 产品中心
	{
		path: '/product',
		name: 'product',
		component: product,
		meta:{
			menuId:'2'
		}
	}, {
		path: '/product/panca',
		name: 'product-panca',
		component: panca,
		meta:{
			menuId:'2-1'
		}
	},{
		path: '/product/kidney',
		name: 'product-kidney',
		component: kidney,
		meta:{
			menuId:'2-2'
		}
	}, {
		path: '/product/lung',
		name: 'product-lung',
		component: lung,
		meta:{
			menuId:'2-3'
		}
	}, {
		path: '/product/mb',
		name: 'product-mb',
		component: mb,
		meta:{
			menuId:'2-4'
		}
	}, {
		path: '/product/ubca',
		name: 'product-ubca',
		component: ubca,
		meta:{
			menuId:'2-5'
		}
	}, {
		path: '/product/coo',
		name: 'product-coo',
		component: coo,
		meta:{
			menuId:'2-6'
		}
	},
	 {
		path: '/product/cases',
		name: 'product-cases',
		component: cases
	}, {
		path: '/product/pancaIntro',
		name: 'product-pancaIntro',
		component: pancaIntro
	},
	// 技术支持
	{
		path: '/technology/support',
		name: 'technology-support',
		component: support,
		meta:{
			menuId:'3-1'
		}
	}, {
		path: '/technology/requirements',
		name: 'technology-requirements',
		component: requirements,
		meta:{
			menuId:'3-2'
		}
	}, {
		path: '/technology/dynamic',
		name: 'technology-dynamic',
		component: dynamic,
		meta:{
			menuId:'3-3'
		}
	}, {
		path: '/technology/achievements',
		name: 'technology-achievements',
		component: achievements,
		meta:{
			menuId:'3-4'
		}
	}, {
		path: '/technology/pipeline',
		name: 'technology-pipeline',
		component: pipeline,
		meta:{
			menuId:'3-5'
		}
	}, {
		path: '/technology/service',
		name: 'technology-service',
		component: service,
		meta:{
			menuId:'3-6'
		}
	},
	// 新闻动态
	{
		path: '/news/company',
		name: 'news-company',
		component: company,
		meta:{
			menuId:'4-1'
		}
	}, {
		path: '/news/industry',
		name: 'news-industry',
		component: industry,
		meta:{
			menuId:'4-2'
		}
	}, {
		path: '/news/research',
		name: 'news-research',
		component: research,
		meta:{
			menuId:'4-3'
		}
	}, {
		path: '/newsinfo',
		name: 'newsinfo',
		component: newsinfo,
		beforeEnter: (to, form, next) => {
			if (to.query.id && to.query.key) {
				next();
			} else {
				next({
					path: '/index'
				});
			}
		}
	},
	// 关于可帮
	{
		path: '/about/introduction',
		name: 'about-introduction',
		component: introduction,
		meta:{
			menuId:'5-1'
		}
	}, {
		path: '/about/partner',
		name: 'about-partner',
		component: partner,
		meta:{
			menuId:'5-2'
		}
	}, {
		path: '/about/culture',
		name: 'about-culture',
		component: culture,
		meta:{
			menuId:'5-3'
		}
	}, {
		path: '/about/contact',
		name: 'about-contact',
		component: contact,
		meta:{
			menuId:'5-4'
		}
	}, {
		path: '/about/institution',
		name: 'about-institution',
		component: institution,
	}, {
		path: '/about/team',
		name: 'about-team',
		component: team
	}, {
		path: '/about/privacypolicy',
		name: 'about-privacypolicy',
		component: privacypolicy,
		meta:{
			menuId:'5-5'
		}
	},

	// 加入我们
	{
		path: '/join/job',
		name: 'join-job',
		component: job,
		meta:{
			menuId:'6-1'
		}
	}, {
		path: '/join/recruitment',
		name: 'join-recruitment',
		component: recruitment,
		meta:{
			menuId:'6-2'
		}
	},
	{
		path: '*',
		redirect: '/',
	}
]

/**
 * 初始化路由 配置
 */
const router = new VueRouter({
	routes,
	mode: "history",
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve, reject) => {
			resolve({
				x: 0,
				y: 0
			})
			behavior: 'smooth'
		})
	}
});

/**
 * 导出路由
 */
export default router