<template>
	<header class="headerBox">
		<el-drawer :visible.sync="dialogMenuVisible" direction="rtl">
			<el-menu :default-active="activeIndex" class="el-menu-vertical-demo" :collapse="false" :unique-opened="true"
				@select="handleSelect">
				<el-menu-item index="1">
					<span slot="title">{{ $t('header.menu.index1') }}</span>
				</el-menu-item>
				<el-submenu index="2">
					<template slot="title">
						<span>{{ $t('header.menu.index2') }}</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="2-1">Canhelp-Origin</el-menu-item>
						<el-menu-item index="2-5">Canhelp-UriBLAD</el-menu-item>
						<el-menu-item index="2-6">Canhelp-COO</el-menu-item>
						<el-menu-item index="2-4">Canhelp-MB</el-menu-item>
						<el-menu-item index="2-3">Canhelp-Lung</el-menu-item>
						<el-menu-item index="2-2">Canhelp-Kidney</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				<el-submenu index="3">
					<template slot="title">
						<span slot="title">{{ $t('header.menu.index3') }}</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="3-1">{{ $t('header.menu.index31') }}</el-menu-item>
						<el-menu-item index="3-2">{{ $t('header.menu.index32') }}</el-menu-item>
						<el-menu-item index="3-3">{{ $t('header.menu.index33') }}</el-menu-item>
						<el-menu-item index="3-4">{{ $t('header.menu.index34') }}</el-menu-item>
						<el-menu-item index="3-5">{{ $t('header.menu.index35') }}</el-menu-item>
						<el-menu-item index="3-6"
							v-if="$i18n.locale=='zh'">{{ $t('header.menu.index36') }}</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				<el-submenu index="4">
					<template slot="title">
						<span slot="title">{{ $t('header.menu.index4') }}</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="4-1">{{ $t('header.menu.index41') }}</el-menu-item>
						<el-menu-item index="4-2"
							v-if="$i18n.locale=='zh'">{{ $t('header.menu.index42') }}</el-menu-item>
						<el-menu-item index="4-3"
							v-if="$i18n.locale=='zh'">{{ $t('header.menu.index43') }}</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				<el-submenu index="5">
					<template slot="title">
						<span slot="title">{{ $t('header.menu.index5') }}</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="5-1">{{ $t('header.menu.index51') }}</el-menu-item>
						<el-menu-item index="5-2">{{ $t('header.menu.index52') }}</el-menu-item>
						<el-menu-item index="5-3">{{ $t('header.menu.index53') }}</el-menu-item>
						<el-menu-item index="5-4">{{ $t('header.menu.index54') }}</el-menu-item>
						<el-menu-item index="5-5">{{ $t('header.menu.index55') }}</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				<el-submenu index="6" v-if="$i18n.locale=='zh'">
					<template slot="title">
						<span slot="title">{{ $t('header.menu.index6') }}</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="6-1">{{ $t('header.menu.index61') }}</el-menu-item>
						<!--el-menu-item index="6-2">{{ $t('header.menu.index62') }}</el-menu-item-->
					</el-menu-item-group>
				</el-submenu>
			</el-menu>
		</el-drawer>
		<div id="myHeader">
			<div class="myImg">
				<img src="../assets/logo.png">
			</div>
			<!-- 页面大于等于992px时显示 横向显示-->
			<div class="menuRow">
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
					text-color="#333" active-text-color="#0f85c1">
					<el-menu-item index="1">&nbsp;{{ $t('header.menu.index1') }}&nbsp;</el-menu-item>
					<el-submenu index="2">
						<template slot="title">{{ $t('header.menu.index2') }}</template>
						<el-menu-item index="2-1">Canhelp-Origin</el-menu-item>
						<el-menu-item index="2-5">Canhelp-UriBLAD</el-menu-item>
						<el-menu-item index="2-6">Canhelp-COO</el-menu-item>
						<el-menu-item index="2-4">Canhelp-MB</el-menu-item>
						<el-menu-item index="2-3">Canhelp-Lung</el-menu-item>
						<el-menu-item index="2-2">Canhelp-Kidney</el-menu-item>
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">{{ $t('header.menu.index3') }}</template>
						<el-menu-item index="3-1">{{ $t('header.menu.index31') }}</el-menu-item>
						<el-menu-item index="3-2">{{ $t('header.menu.index32') }}</el-menu-item>
						<el-menu-item index="3-3">{{ $t('header.menu.index33') }}</el-menu-item>
						<el-menu-item index="3-4">{{ $t('header.menu.index34') }}</el-menu-item>
						<el-menu-item index="3-5">{{ $t('header.menu.index35') }}</el-menu-item>
						<el-menu-item index="3-6"
							v-if="$i18n.locale=='zh'">{{ $t('header.menu.index36') }}</el-menu-item>
					</el-submenu>
					<el-submenu index="4">
						<template slot="title">{{ $t('header.menu.index4') }}</template>
						<el-menu-item index="4-1">{{ $t('header.menu.index41') }}</el-menu-item>
						<el-menu-item index="4-2"
							v-if="$i18n.locale=='zh'">{{ $t('header.menu.index42') }}</el-menu-item>
						<el-menu-item index="4-3"
							v-if="$i18n.locale=='zh'">{{ $t('header.menu.index43') }}</el-menu-item>
					</el-submenu>
					<el-submenu index="5">
						<template slot="title">{{ $t('header.menu.index5') }}</template>
						<el-menu-item index="5-1">{{ $t('header.menu.index51') }}</el-menu-item>
						<el-menu-item index="5-2">{{ $t('header.menu.index52') }}</el-menu-item>
						<el-menu-item index="5-3">{{ $t('header.menu.index53') }}</el-menu-item>
						<el-menu-item index="5-4">{{ $t('header.menu.index54') }}</el-menu-item>
						<el-menu-item index="5-5">{{ $t('header.menu.index55') }}</el-menu-item>
					</el-submenu>
					<el-submenu index="6" v-if="$i18n.locale=='zh'">
						<template slot="title">{{ $t('header.menu.index6') }}</template>
						<el-menu-item index="6-1">{{ $t('header.menu.index61') }}</el-menu-item>
						<!--el-menu-item index="6-2">{{ $t('header.menu.index62') }}</el-menu-item-->
					</el-submenu>
				</el-menu>
				<!-- 中英文切换 -->
				<div class="languageChange">
					<p @click="changeEN">EN</p>
					<!-- <p @click="changeLanguage">{{ $t('header.language') }}</p> -->
				</div>
			</div>
			<!-- 页面小于992px时显示 纵向显示-->
			<div class="menuColumn">
				<!-- 中英文切换 -->
				<div class="languageChange">
					<p @click="changeEN">EN</p>
					<!-- <p @click="changeLanguage">{{ $t('header.language') }}</p> -->
				</div>
				<i class="el-icon-s-unfold" @click="openMenu"></i>
			</div>

		</div>
	</header>
</template>

<script>
	export default {
		data() {
			return {
				activeIndex: '1',
				// 弹框
				dialogMenuVisible: false,
			}
		},
		mounted() {
			var that = this;
			that.initRoute();
			// 监听界面>=768时,若弹框为true
			window.addEventListener("resize", () => {
				if (window.innerWidth >= 768) {
					that.dialogMenuVisible = false;
				}
			});
		},
		watch: {
			// 监听路由变化
			$route(to, from) {
				var that = this;
				that.initRoute();
			}
		},
		methods: {
			// 跳转英文官网
			changeEN() {
				window.location.href = this.windowUrl;
			},
			// 语言切换
			changeLanguage() {
				var that = this;
				if (that.$i18n.locale == 'zh') {
					that.$i18n.locale = 'en'
				} else {
					that.$i18n.locale = 'zh'
				}
			},
			// 初始化时判断路由
			initRoute() {
				var that = this;
				var name = that.$route.name;
				if (name) {
					if (name == 'newsinfo') {
						if (that.$route.query.key == '1') {
							that.activeIndex = '4-1';
						} else if (that.$route.query.key == '2') {
							that.activeIndex = '4-2';
						} else if (that.$route.query.key == '4') {
							that.activeIndex = '4-3';
						}
					}
					else{
						if(that.$route.meta && that.$route.meta.menuId){
							that.activeIndex = that.$route.meta.menuId;
						}else{
							that.activeIndex = '';					}
						}
				}else{
					that.activeIndex = '';
				}
			},
			// 菜单抽屉组件
			openMenu() {
				this.dialogMenuVisible = true;
			},
			handleSelect(key, keyPath) {
				var that = this;
				if (navigator.onLine == true) {
					var arr=that.$router.options.routes;
					for(var i=0;i<arr.length;i++){
						if(arr[i].meta && arr[i].meta.menuId==key){
							that.$router.push(arr[i].path);
						}
					}
				} else {
					that.$message.error('请检查当前网络!');
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	#myHeader {
		position: fixed;
		top: 0;
		z-index: 11;
		background-color: #FFFFFF !important;
		display: flex;
		align-items: center;
		justify-content: space-around;
		color: #333333;
		box-sizing: border-box;
		padding: 0.5rem 10%;
		width: 100%;

		.myImg {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				height: 3.0rem;
			}
		}

		.menuRow {
			display: flex;
			align-items: center;
			justify-content: space-around;
			width: 100%;

			ul {
				margin: 0 auto !important;
			}
		}

		.menuColumn {
			display: flex;
			align-items: center;
			color: #666666;

			.el-icon-s-unfold {
				font-size: 1.8rem;
			}

			.el-icon-s-unfold:hover {
				cursor: pointer;
			}
		}

		.languageChange {
			p {
				cursor: pointer;
				text-align: center;
				font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
			}
		}
	}

	@media screen and (min-width: 768px) {
		.menuRow {
			display: inline-block;
		}

		.menuColumn {
			display: none !important;
		}
	}

	@media screen and (max-width: 767px) {
		#myHeader {
			justify-content: space-between !important;
		}

		.menuRow {
			display: none !important;
		}

		.menuColumn {
			display: inline-block;
		}
	}

	@media screen and (min-width:768px) and (max-width:992px) {
		#myHeader {
			padding: 1% 0 !important;
		}
	}
</style>