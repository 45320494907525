<template>
	<div class="productMenu">
		<div class="productTitle">产品列表</div>
		<el-menu :default-active="activeIndex" class="el-menu-vertical-demo" text-color="#333333"
			active-text-color="#0472BF" @select="handleSelect">
			<el-menu-item index="2-1">Canhelp-Origin</el-menu-item>
			<el-menu-item index="2-5">Canhelp-UriBLAD</el-menu-item>
			<el-menu-item index="2-6">Canhelp-COO</el-menu-item>
			<el-menu-item index="2-4">Canhelp-MB</el-menu-item>
			<el-menu-item index="2-3">Canhelp-Lung</el-menu-item>
			<el-menu-item index="2-2">Canhelp-Kidney</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeIndex: "",
			}
		},
		mounted() {
			var that = this;
			that.initRoute();
		},
		methods: {
			// 初始化时判断路由
			initRoute() {
				var that = this;
				switch (that.$route.name) {
					case 'product-panca':
						that.activeIndex = '2-1';
						break;
					case 'product-kidney':
						that.activeIndex = '2-2';
						break;
					case 'product-lung':
						that.activeIndex = '2-3';
						break;
					case 'product-mb':
						that.activeIndex = '2-4';
						break;
					case 'product-ubca':
						that.activeIndex = '2-5';
						break;
					case 'product-ubca':
						that.activeIndex = '2-5';
						break;
					case 'product-coo':
						that.activeIndex = '2-6';
						break;
				}
			},
			handleSelect(key, keyPath) {
				var that = this;
				if (navigator.onLine == true) {
					switch (key) {
						case '2-1':
							that.$router.push('/product/panca');
							break;
						case '2-2':
							that.$router.push('/product/kidney');
							break;
						case '2-3':
							that.$router.push('/product/lung');
							break;
						case '2-4':
							that.$router.push('/product/mb');
							break;
						case '2-5':
							that.$router.push('/product/ubca');
							break;
						case '2-6':
							that.$router.push('/product/coo');
							break;
					}
				} else {
					that.$message.error('请检查当前网络!');
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.productMenu {
		width: 240px;

		.productTitle {
			height: 40px;
			line-height: 40px;
			font-weight: bold;
			font-size: 0.7rem;
			color: #FFFFFF;
			background: #0472BF;
			padding-left: 25px;
		}

		.el-menu {
			border-right: none;
		}

		.el-menu-item {
			font-size: 0.8rem !important;
			height: 48px;
			line-height: 48px;
			border-left: 3px solid  #ffffff !important;
		}
		.el-menu-item.is-active{
			border-left: 3px solid  #0472BF !important;
		}
		.el-menu-item:focus,
		.el-menu-item:hover {
			background: none;
			color: #0472BF !important;
		}
	}
</style>