<template>
	<main class="myProduct">
		<Nav />
		<!-- 主要内容 -->
		<div class="productMain">
			<!-- 左侧菜单 -->
			<product-menu class="leftDetail"></product-menu>
			<!-- 右侧内容 -->
			<product-detail class="rightDetail" :detailData="detailData"></product-detail>
		</div>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	import productMenu from "./productMenu.vue";
	import productDetail from "./productDetail.vue";
	export default {
		name: 'product-mb',
		components: {
			Nav,
			productMenu,
			productDetail
		},
		data() {
			return {
				detailData:{
					name:'Canhelp-MB髓母细胞瘤分子分型检测',
					indication:['髓母细胞瘤（MB）'],
					background:'髓母细胞瘤（Medulloblastoma, MB）是儿童最常见的恶性脑肿瘤，约占所有儿童癌症死亡人数的10%。目前主要以手术切除为主辅助放疗和化疗等综合治疗。对于3岁以下儿童放化疗易引起严重的副作用和后遗症。此外，仍有30%~50%的患儿治疗效果差，预后不佳。根据2016年WHO中枢神经系统肿瘤分类，高通量基因表达谱可将髓母细胞瘤分为WNT，SHH，Group 3和Group 4四种分子亚型。各个分子亚型的流行病学、临床表现和遗传学特征各不相同，并且在治疗和预后上具有显著差别。因此，髓母细胞瘤分子分型是实现精准治疗的前提和基础。然而，目前临床上缺乏有效的分子检测手段用于髓母细胞瘤的分子分型。',
					description:'Canhelp-MB是一款基因表达谱检测与人工智能相结合的分子诊断产品。基于髓母细胞瘤基因表达谱大数据，筛选出24个髓母细胞瘤组织特异性基因，采用实时定量聚合酶链反应(qRT-PCR)检测石蜡包埋肿瘤组织中基因的表达水平来进行分子分型。该产品可以用来对四种主要的髓母细胞瘤分子分型：WNT, SHH, Group 3, Group 4进行分类。',
					advantage:[{
						img:require('../../assets/images/productDetail/mb/icon-1.png'),
						title:'权威',
						text:'2016年WHO中枢神经系统指南建议结合分子分型指导髓母细胞瘤预后和治疗。'
					},{
						img:require('../../assets/images/productDetail/mb/icon-2.png'),
						title:'精准',
						text:'基于23个基因mRNA表达水平计算，分型准确率＞95%。'
					},{
						img:require('../../assets/images/productDetail/mb/icon-3.png'),
						title:'客观',
						text:'AI算法自动进行数据分析，结果不受人为主观判断干扰。'
					},{
						img:require('../../assets/images/productDetail/mb/icon-4.png'),
						title:'快速',
						text:'荧光定量PCR平台检测，3-5个工作日内完成检测并出具报告。'
					}],
					people:['小儿或成人髓母细胞瘤患者','期望减少治疗后遗症的儿童MB患者','放化疗前需进行分子分型的MB患者']
				},
			}
		},
		mounted() {
			var that = this;
			that.show = true;
		}
	}
</script>

<style lang="scss" scoped>
	.myProduct{
		.productMain {
			padding: 2% 15% 5% !important;
			display: flex;
		}
		.leftDetail{
			width: 200px;
			flex-shrink: 0;
			margin-right: 20px;
		}
		.rightDetail{
			width: 100%;
		}
	}
	@media (max-width:1024px){
		.leftDetail{
			display:none !important;
		}
	}
</style>