export default class SocketService {
	// 单例
	static instance = null;
	static get Instance() {
		if (!this.instance) {
			this.instance = new SocketService()
		}
		return this.instance
	}

	// 和服务端连接的socket对象
	ws = null;

	// 存储回调函数
	callBackMapping = {};

	// 标识是否连接成功
	connected = false;

	// 记录重试的次数
	sendRetryCount = 0;

	// 重新连接尝试的次数
	connectRetryCount = 0;
	//定义guid变量
	guid = "";

	// 生产guid
	getGuid() {
		return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0,
				v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	//  定义连接服务器的方法
	connect(page) {
		// 连接服务器
		if (!window.WebSocket) {
			return console.log('您的浏览器不支持WebSocket')
		}
		// 判断localstorage中是否有此guid
		this.guid = localStorage.getItem("guid");
		if ((!this.guid) || (this.guid == "undefined") || (this.guid.length != 32)) {
			this.guid = this.getGuid();
			localStorage.setItem("guid", this.guid);
		}
		this.ws = new WebSocket(page.wsUrl + this.guid);
		// 连接成功的事件
		this.ws.onopen = (event) => {
			this.connected = true;
			// 重置重新连接的次数
			this.connectRetryCount = 0;
			console.log("连接成功");
			// 请求加载历史信息
			page.getHistoryList(this.guid);
		}
		// 1.连接服务端失败
		// 2.当连接成功之后, 服务器关闭的情况
		this.ws.onclose = () => {
			this.connected = false;
			this.connectRetryCount++;
			console.log("已断开");
			if(!page.collapse){
				setTimeout(() => {
					this.connect(page);
				}, 500 * this.connectRetryCount)
			}
		}
		// 得到服务端发送过来的数据
		this.ws.onmessage = msg => {
			page.handleData(msg)
		}
	}

	// 回调函数的注册
	registerCallBack(socketType, callBack) {
		this.callBackMapping[socketType] = callBack
	}

	// 取消某一个回调函数
	unRegisterCallBack(socketType) {
		this.callBackMapping[socketType] = null
	}

	// 发送数据的方法
	send(page, data) {
		// 判断此时此刻有没有连接成功
		if (this.connected) {
			this.sendRetryCount = 0;
			this.ws.send(data);
			var obj = {
				time: new Date().getTime(),
				message: page.inputValue,
				direction: 0
			};
			page.dataList.push(obj);
			page.inputValue = "";
		} else {
			this.sendRetryCount++;
			setTimeout(() => {
				this.send(data);
			}, this.sendRetryCount * 500)
		}
	}
}