<template>
	<main class="myAbout">
		<Nav />
		<transition name="slide-top">
			<div v-if="show" class="contactDiv">
				<h1>管理团队</h1>
				<p class="techP">致力研发<span>新一代</span><span>肿瘤分子诊断试剂</span></p>
				<div class="teamInfo">
					<el-row :gutter="40">
						<el-col :xs="12" :sm="8">
							<div class="colDiv colDiv1" @click="activeMethod(1)">
								<img src="../../assets/images/team_1.jpg"/>
								<div class="roll"></div>
							</div>
						</el-col>
						<el-col :xs="12" :sm="8">
							<div class="colDiv colDiv2 colDivActive" @click="activeMethod(2)">
								<img src="../../assets/images/team_2.jpg"/>
								<div class="roll"></div>
							</div>
						</el-col>
						<el-col :xs="12" :sm="8">
							<div class="colDiv colDiv3" @click="activeMethod(3)">
								<img src="../../assets/images/team_3.jpg"/>
								<div class="roll"></div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="teamText">
					<div class="team1" v-show="key==1">
						<h3>副总经理</h3>
						<p class="name">张弘 / Zhang Hong</p>
						<div class="cont">
							<b>工作职责：</b>
							<br>
							• 协助总经理进行公司日常事务管理；分管：销售部、市场部等前台部门
							<br><br>
							<b>个人简介：</b>
							<br>
							• 英国伦敦大学学院金融风险管理、UH国际商务管理硕士；
							<br>
							• 曾任职于迪安诊断投资部，参与多个IVD产品的投资及运营管理；
							<br>
							• 负责参与大健康领域多个细分行业的投资并购及投后管理，累计投资金额超30亿，对IVD行业有较深的理解。
						</div>
					</div>
					
					<div class="team2" v-show="key==2">
						<h3>董事长、总经理</h3>
						<p class="name">徐清华 / Xu Qinghua</p>
						<div class="cont">
							<b>工作职责：</b>
							<br>
							• 负责公司战略制定、产品研发、转产、生产、质量的工作开展；分管：研发部、生产部、质量部
							<br><br>
							<b>个人简介：</b>
							<br>
							• 复旦大学肿瘤学博士、比利时鲁汶大学生物化学、生物信息学硕士；
							<br>
							• 超过10年肿瘤标志物及AI算法结合产品研究经验；
							<br>
							• 曾任法国梅里埃公司资深科学家，成功研发多个肿瘤诊断相关产品；
							<br>
							现任中国研究型医院学会病理学专业委员会常委、徐州医学院兼职教授、上海市计算机协会生物信息学专委会副主委。
						</div>
					</div>
					
					<div class="team3" v-show="key==3">
						<h3>副总经理</h3>
						<p class="name">浦诚 / Peter Pu</p>
						<div class="cont">
							<b>工作职责：</b>
							<br>
							• 协助总经理进行公司日常事务管理；分管：财务部、综合管理部、IT部等后台部门
							<br><br>
							<b>个人简介：</b>
							<br>
							• 比利时鲁汶大学-国际经济学硕士；
							<br>
							• 超过10年的财务管理以及企业级 ERP系统实施经验；
							<br>
							• 曾就职于壳牌皇家石油、埃森哲、阿克苏诺贝尔等世界500强企业；拥有丰富的项目管理经验。
						</div>
					</div>
					
				</div>
			</div>
		</transition>	
	</main>
</template>
<script>
	import Nav from "../nav.vue";
	import $ from "jquery";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				key:2,
				show:false
			}
		},
		mounted() {
			var that=this;
			that.show=true;
		},
		methods: {
			activeMethod(val) {
				var that=this;
				that.key=val;
				$('.colDiv').removeClass('colDivActive');
				if(val==1){
					$('.colDiv1').addClass('colDivActive');
				}else if(val==2){
					$('.colDiv2').addClass('colDivActive');
				}else{
					$('.colDiv3').addClass('colDivActive');
				}
			}
		},

	}
</script>

<style lang="scss" scoped>
	// .myAbout{
	// 	background:#f0f3f5 !important;
	// }
 //    #container {
	// 	overflow: hidden;
	// 	width: 100%;
	// 	height: 600px;
	// 	margin: 0;
	// 	font-family: "微软雅黑";
	// }
	@media only screen and (max-width:991px) {
		.cont{
			width: 100% !important;
		}
	}
	
</style>
