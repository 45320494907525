<template>
	<main class="myAbout">
		<Nav />
		<transition name="slide-top">
			<div v-if="show" class="introductionDiv">
				<div class="introductionDiv1">
					<el-row :gutter="30">
						<el-col :xs="24" :sm="12" :md="12">
							<img src="../../assets/images/5_03.png">
						</el-col>
						<el-col :xs="24" :sm="12" :md="12">
							<p class="p1">{{ $t('about.introduction.h1') }}</p>
							<p class="p2">
								{{ $t('about.introduction.p1') }}<span>{{ $t('about.introduction.p2') }}</span><span>{{
									$t('about.introduction.p3') }}</span>
							</p>
							<hr>
							<p class="p3" v-html="$t('about.introduction.text')"></p>
						</el-col>
					</el-row>
				</div>
				<div class="introductionDiv2">
					<div class="title">发展历程<span class="sub-title"> / Development path</span></div>
					<div class="nav">
						<span v-for="(item, index) in timelines" :class="[{'nav-selected': index == timelineSelectedIndex}]" @click="clickTimelineItem(index)">{{ item.year }}</span>
					</div>
					<div class="screen1">
						<div v-for="(item, index) in timelines" :key="index" 
							:style="getTimelineItemStyle(index)">
							<div class="pointer"></div>
							<h2>{{ item.year }}</h2>
							<p>{{ item.title }}</p>
							<span>{{ item.content }}</span>
						</div>
					</div>
					<el-timeline class="screen2" :reverse="true">
						<el-timeline-item v-for="item in timelines" :timestamp="item.year" placement="top" color='#31B0B5'>
							<el-card>
								<h4>{{ item.title }}</h4>
								<p>{{ item.content }}</p>
							</el-card>
						</el-timeline-item>
					</el-timeline>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
import Nav from "../nav.vue";
export default {
	components: {
		Nav
	},
	data() {
		return {
			show: false,
			timelines: [{
				year: '2014',
				title: '可帮基因成立',
				content: '肿瘤基因表达谱数据库及核心算法设计完成'
			}, {
				year: '2015',
				title: '完成天使轮融资',
				content: '多基因检测肿瘤组织起源试剂盒完成研发'
			}, {
				year: '2016',
				title: '与赛默飞签署合作协议',
				content: '多基因检测肿瘤组织起源相关研究成果国际知名期刊发表'
			}, {
				year: '2017',
				title: '获得元禾原点投资',
				content: '与复旦大学附属肿瘤医院联合发起的多原发和不明原发肿瘤临床试验正式启动'
			}, {
				year: '2018',
				title: '产品欧盟认证',
				content: '肿瘤组织起源基因检测试剂盒进入CFDA注册试验阶段'
			}, {
				year: '2019',
				title: '膀胱癌基因检测完成研发',
				content: '膀胱癌无创基因检测临床数据发表于国际知名期刊'
			}, {
				year: '2020',
				title: '获得中金资本投资',
				content: '可帮医学临床实验室获批'
			}, {
				year: '2022',
				title: '产品获NMPA三类认证',
				content: '肿瘤组织起源基因检测获得NMPA认证和欧盟CE认证'
			}, {
				year: '2023',
				title: 'ESMO年会上发布重要成果',
				content: '全球首个原发不明肿瘤III期临床试验达到主要研究终点'
			}],
			timelineSelectedIndex: 0
		}
	},
	mounted() {
		var that = this;
		that.show = true;
		this.getTimelineItemStyle = function(index) {
			var offset = this.timelineSelectedIndex;
			if (this.timelineSelectedIndex > 2) {
				offset = 2;
			}
			var value = 5.65 + (index - offset) * 10.515;
			return {
				left: value + "%"
			};
		};
		this.clickTimelineItem = function(index) {
			this.timelineSelectedIndex = index;
		};
	}

}
</script>

<style lang="scss">
.introductionDiv2 {
	box-sizing: border-box;
	padding: 2% 10%;
	margin: 0 auto;
	background-size: cover;
	color: #666666;
	background-image: url('../../assets/images/about/bg_timelines.png');

	.title {
		padding: 3% 10% 20px 10%;
		color: #333;

		.sub-title {
			color: #B3B3B3;
		}
	}

	.nav {
		padding: 0 10% 90px 10%;

		span {
			font-size: 14px;
			margin-right: 25px;
			cursor: pointer;
		}
		span.nav-selected {
			color: #0472BF;
		}
	}

	.screen2 {
		background: url(../../assets/images/5_05.jpg) center;
		padding: 5%;
		color: #666666;

		.el-timeline-item__tail {
			border-left: 2px solid #D7D9DA !important;
		}

		.is-top {
			font-size: 1.2rem;
		}

		h4 {
			color: #333333;
			font-size: 1rem;
			line-height: 1.3rem;
		}

		p {
			color: #666666;
			font-size: 0.7rem;
			line-height: 1rem;
			margin-top: 0.5rem;
		}
	}
}

.introductionDiv2 .screen1 {
	background: url('../../assets/images/about/timelines.png') center no-repeat;
	height: 400px;
	position: relative;
	background-size: 100% 63px;

	div {
		font-size: 12px;
		position: absolute;
		width: 15%;
		top: 28px;

		div.pointer {
			background-image: url('../../assets/images/about/timeline_tick.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 9px;
			height: 170px;
			position: absolute;
			left: -10%;
			top: -30px;
		}

		h2 {
			color: #0472BF;
			margin: 0 0 3px 0;
		}

		p {
			color: #333;
			font-size: 1.2em;
			font-weight: bold;
			margin: 0 0 5px 0;
		}

		span {
			color: #666;
		}
	}

	div:nth-child(even) .pointer {
		transform: rotate(180deg);
		top: -66px;
	}

	div:nth-child(even) {
		top: 297px;
	}
}

@media only screen and (min-width:992px) {
	.el-col-md-4-8 {
		width: 20%;
	}
}

@media only screen and (min-width: 1200px) {
	.introductionDiv2 {
		padding: 2% 0 !important;
	}

	.screen1 {
		display: block;
	}

	.screen2 {
		display: none;
	}
}

@media only screen and (max-width: 1200px) {
	.screen1 {
		display: none;
	}
	.introductionDiv2 .nav {
		display: none;
	}

	.screen2 {
		display: block;
	}
}
</style>
