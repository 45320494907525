<template>
	<main class="myJoin">
		<Nav />
		<transition name="slide-top">
			<div v-if="show" class="jobDiv">
				<div class="jobDiv1">
					<h1>加入你喜欢的事业</h1>
					<p>你自信飞扬，潇洒英姿，你说你准备好了，世界就在你的脚下，但唯独缺少一个平台！</p>
					<p>——可帮基因欢迎年轻的你</p>
					<p>我们希望你拥有特别的视角，独立思考问题，善于组织各方资源。在每项工作中始终能保持对结果负责的态度和积极向上乐于分享的团队精神。</p>
				</div>
				<div class="jobDiv2">
					<!-- :row-key="getRowKeys" :expand-row-keys="expands" -->
					<el-table ref="table" :data="tableData" style="width: 100%">
						<el-table-column type="expand">
							<template slot-scope="props">
								<div class="demo-table-expand">
									<div class="expandDiv1">
										<p>岗位职责：</p>
										<ol>
											<li v-for="(item, index) in props.row.duty">
												{{ item }}
											</li>
										</ol>
									</div>
									<div class="expandDiv2">
										<p>岗位要求：</p>
										<ol>
											<li v-for="(item, index) in props.row.require">
												{{ item }}
											</li>
										</ol>
									</div>
									<a href="mailto:hr@cancerhelp.cn" translate="JOB_SEND_CV" class="sendButton">投递简历至邮箱</a>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="职位名称" prop="name">
						</el-table-column>
						<el-table-column label="职位部门" prop="department">
						</el-table-column>
						<el-table-column label="工作地点" prop="area">
						</el-table-column>
						<el-table-column label="发布日期" prop="date">
						</el-table-column>
						<el-table-column label="" width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.expanded">
									<el-button type="text" @click="toogleExpand(scope.row)">收起</el-button>
								</span>
								<span v-else>
									<el-button type="text" @click="toogleExpand(scope.row)">展开</el-button>
								</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="jobDiv3">
					<div class="piepeDiv1">
						<img src="../../assets/images/3_19.jpg">
						<p>整体流程</p>
					</div>
					<div class="piepeDiv2">
						<el-row>
							<el-col :xs='24' :sm="24">
								<div>
									<img src="../../assets/images/job/1.png">
									<p>投递简历</p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/jt.png">
									<p></p>
								</div>
								<div>
									<img src="../../assets/images/job/2.png">
									<p>初试</p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/jt.png">
									<p></p>
								</div>
								<div>
									<img src="../../assets/images/job/3.png">
									<p>复试</p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/jt.png">
									<p></p>
								</div>
								<div>
									<img src="../../assets/images/job/4.png">
									<p>Offer</p>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
import Nav from "../nav.vue";
export default {
	components: {
		Nav
	},
	data() {
		return {
			show: false,
			tableData: []
		}
	},
	mounted() {
		var that = this;
		that.show = true;
		that.getJobs();
	},
	methods: {
		toogleExpand(row) {
			var that = this;
			row.expanded = !row.expanded;
			that.$refs.table.toggleRowExpansion(row, row.expanded);
		},
		getJobs() {
			var that = this;
			that.$axios
				.get("/api/job")
				.then(function (res) {
					that.tableData = res.data.data;
					that.tableData.forEach(val => {
						that.$set(val, "expanded", false);
					});
				});
		}
	},
}
</script>

<style lang="scss" scoped>
.myAbout {
	background: #f0f3f5 !important;
}

@media only screen and (max-width: 460px) {
	.piepeDiv2 {
		.el-col {
			display: table-column !important;
		}
	}
}

@media only screen and (max-width: 991px) {
	.piepeDiv2 {
		.el-row .el-col {
			div:nth-child(even) {
				display: none;
			}
		}
	}
}
</style>
