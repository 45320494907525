<template>
	<main class="myTech">
		<Nav />
		<!-- 主要内容 -->
		<transition name="slide-top">
			<div v-if="show" class="techMain">
				<h1>{{$t('tech.dynamic.h1')}}</h1>
				<p class="techP">{{$t('tech.dynamic.p1')}}<span>{{$t('tech.dynamic.p2')}}</span><span>{{$t('tech.dynamic.p3')}}</span></p>
				<div class="dynamicDiv">
					<el-row :gutter="30">
						<el-col :xs='24' :sm="24" :md="8" :lg="8" :xl="8">
							<div class="dynamicBox leftDiv1" @click="activeMethod(1)">
								<p class="p1"><span>{{$t('tech.dynamic.itme1.p')}}</span></p>
								<div class="divP">
									<img v-if="activeName==1" src="../../assets/images/3_09a.jpg">
									<img v-else src="../../assets/images/3_09.jpg">
									<p><span>01</span>{{$t('tech.dynamic.itme1.h3')}}</p>
								</div>
							</div>
							<div class="dynamicBox leftDiv2" @click="activeMethod(2)">
								<p class="p1"><span>{{$t('tech.dynamic.itme2.p')}}</span></p>
								<div class="divP">
									<img v-if="activeName==2" src="../../assets/images/3_09a.jpg">
									<img v-else src="../../assets/images/3_10.jpg">
									<p><span>02</span>{{$t('tech.dynamic.itme2.h3')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs='24' :sm="24" :md="8" :lg="8" :xl="8">
							<div class="centerDiv1">
								<div v-if="$i18n.locale=='zh'">
									<img v-show="activeName==1" src="../../assets/images/tech/1.png">
									<img v-show="activeName==2" src="../../assets/images/tech/2.png">
									<img v-show="activeName==3" src="../../assets/images/tech/3.png">
									<img v-show="activeName==4" src="../../assets/images/tech/4.png">
									<img v-show="activeName==5" src="../../assets/images/tech/5.png">
								</div>
								<div v-else>
									<img v-show="activeName==1" src="../../assets/images/tech/1_en.png">
									<img v-show="activeName==2" src="../../assets/images/tech/2_en.png">
									<img v-show="activeName==3" src="../../assets/images/tech/3_en.png">
									<img v-show="activeName==4" src="../../assets/images/tech/4_en.png">
									<img v-show="activeName==5" src="../../assets/images/tech/5_en.png">
								</div>
							</div>
							<div class="dynamicBox centerDiv2" @click="activeMethod(3)">
								<p class="p1"><span>{{$t('tech.dynamic.itme3.p')}}</span></p>
								<div class="divP">
									<img v-if="activeName==3" src="../../assets/images/3_09a.jpg">
									<img v-else src="../../assets/images/3_11.jpg">
									<p><span>03</span>{{$t('tech.dynamic.itme3.h3')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs='24' :sm="24" :md="8" :lg="8" :xl="8" class="rightDiv">
							<div class="dynamicBox rightDiv1" @click="activeMethod(5)">
								<p class="p1"><span>{{$t('tech.dynamic.itme5.p')}}</span></p>
								<div class="divP">
									<img v-if="activeName==5" src="../../assets/images/3_09a.jpg">
									<img v-else src="../../assets/images/3_13.jpg">
									<p><span>05</span>{{$t('tech.dynamic.itme5.h3')}}</p>
								</div>
							</div>
							<div class="dynamicBox rightDiv2" @click="activeMethod(4)">
								<p class="p1"><span>{{$t('tech.dynamic.itme4.p')}}</span></p>
								<div class="divP">
									<img v-if="activeName==4" src="../../assets/images/3_09a.jpg">
									<img v-else src="../../assets/images/3_12.jpg">
									<p><span>04</span>{{$t('tech.dynamic.itme4.h3')}}</p>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	import $ from 'jquery'
	import Nav from "../nav.vue";	
	export default {
		components:{
			Nav
		},
		data() {
			return {
				activeName: 1,
				show:false
			}
		},
		mounted() {
			var that=this;
			that.show=true
		},
		methods: {
			activeMethod(val) {
				var that=this;
				that.activeName=val;
				$('.colDiv').removeClass('colDivActive');
				if(val==1){
					$('.colDiv1').addClass('colDivActive');
				}else if(val==2){
					$('.colDiv2').addClass('colDivActive');
				}else{
					$('.colDiv3').addClass('colDivActive');
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 991px){
   .dynamicDiv{
	   .centerDiv1{
		   display: none;
	   }
	   .leftDiv1{
		   display: flex;
		   flex-direction: row-reverse;
		   align-items: center;
		   justify-content: flex-start;
		   margin-bottom: 8% !important;
		   .divP{
			   position: relative;
			   img{
				   width: 4.5rem !important;
				   height: 4.5rem !important;
			   }
			   p{
				   position: absolute;
				   white-space: nowrap;
				   margin: 0 auto !important;
			   }
		   }
		   .p1{
			   text-align: left;
			   margin: 0 auto !important;
			   margin-left: 2% !important;
			   span{
				   width: 100% !important;
			   }
		   }
	   }
	   .leftDiv2,.centerDiv2{
		   @extend .leftDiv1;
	   }
	   .rightDiv{
		   display: flex;
		   flex-direction: column-reverse;
		   .rightDiv1{
			    @extend .leftDiv1;
		   }
		   .rightDiv2{
		   	    @extend .leftDiv1;
		   }
	   }
   }
}
</style>
