<template>
	<main class="myAbout">
		<Nav />
		<transition name="slide-top">
			<div v-if="show">
				<div class="contactDiv">
					<h1>{{$t('about.contact.h1')}}</h1>
					<p class="techP">
						{{$t('about.contact.p1')}}<span>{{$t('about.contact.p2')}}</span><span>{{$t('about.contact.p3')}}</span>
					</p>
					<div class="contactInfo">
						<el-row>
							<el-col :xs="24" :sm="8">
								<div class="colDiv">
									<img src="../../assets/images/5_38.jpg" />
									<p class="p1">{{$t('about.contact.item1.p')}}</p>
									<p class="p2">{{$t('about.contact.item1.text')}}</p>
									<div class="border"></div>
								</div>
							</el-col>
							<el-col :xs="24" :sm="8">
								<div class="colDiv">
									<img src="../../assets/images/5_39.jpg" />
									<p class="p1">{{$t('about.contact.item2.p')}}</p>
									<p class="p2">{{$t('about.contact.item2.text')}}</p>
									<div class="border"></div>
								</div>
							</el-col>
							<el-col :xs="24" :sm="8">
								<div class="colDiv">
									<img src="../../assets/images/5_40.jpg" />
									<p class="p1">{{$t('about.contact.item3.p')}}</p>
									<p class="p2">{{$t('about.contact.item3.text')}}</p>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<!-- 百度地图 -->
				<!-- <div id="container"></div> -->
			</div>
		</transition>
	</main>
</template>
<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				show: false
			}
		},
		mounted() {
			var that = this;
			that.show = true;
			// setTimeout(function() {
			// 	that.init();
			// }, 100)
		},
		methods: {
			init() {
				const that = this;
				// 地图初始,创建地图函数
				var map = new BMap.Map('container', {
					enableHighResolution: true
				})
				var point = new BMap.Point(120.319406, 30.454549)
				map.centerAndZoom(point, 12);
				// 事件
				// map.enableScrollWheelZoom(); //滚轮缩放
				// map.enableKeyboard(); //键盘上下左右
				map.clearOverlays(); //清除地图覆盖物

				var address = "杭州可帮基因科技有限公司总部"; //查询的详细地址
				var marker = new BMap.Marker(point);
				var opts = {
					title: '<h4>' + address + '</h4>', // 信息窗口标题
					enableMessage: true, //设置允许信息窗发送短息
					message: ""
				};
				var label = new BMap.Label(address, {
					"offset": new BMap.Size(15, -15)
				});
				var infoWindow = new BMap.InfoWindow("杭州市余杭区新颜路22号D区3楼<br>400-168-3904", opts);
				// 添加控件
				marker.setLabel(label);
				marker.addEventListener("click", function() {
					this.openInfoWindow(infoWindow);
				});
				map.addOverlay(marker);
			}
		},

	}
</script>

<style lang="scss" scoped>
	.myAbout {
		background: #f0f3f5 !important;
	}

	#container {
		overflow: hidden;
		width: 100%;
		height: 600px;
		margin: 0;
		font-family: "微软雅黑";
	}

	@media only screen and (max-width:767px) {
		.border {
			display: none !important;
		}

		.colDiv {
			border-bottom: 1px solid rgba(204, 204, 204, 0.8);
		}

		#container {
			height: 350px;
		}
	}
</style>
