<template>
	<div id="app">
		<Header></Header>
		<router-view></router-view>
		<Footer></Footer>
		<div class="customerServiceFixed">
			<customerService></customerService>
		</div>
		<!-- <iframe :src="src" ref="iframe"></iframe> -->
	</div>
</template>

<script>
	import Header from './components/header.vue'
	import Footer from './components/footer.vue'
	import customerService from './components/customerService.vue'
	export default {
		name: 'App',
		components: {
			Header,
			Footer,
			customerService,
		},
	}
</script>

<style>
	.el-textarea__inner{
		font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
	}
	.customerServiceFixed{
		position: fixed;
		bottom: 24px;
		right: 24px;
		z-index: 2000;
	}
</style>