<template>
	<main class="myProduct">
		<Nav />
		<!-- 主要内容 -->
		<div class="productMain">
			<!-- 左侧菜单 -->
			<product-menu class="leftDetail"></product-menu>
			<!-- 右侧内容 -->
			<product-detail class="rightDetail" :detailData="detailData"></product-detail>
		</div>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	import productMenu from "./productMenu.vue";
	import productDetail from "./productDetail.vue";
	export default {
		name: 'product-lung',
		components: {
			Nav,
			productMenu,
			productDetail
		},
		data() {
			return {
				detailData:{
					name:'Canhelp-Lung 肺癌基因检测',
					indication:['非小细胞肺癌'],
					background:'肺癌（Lung Carcinoma）是人类癌症死亡的主要原因之一，其发病率和致死率居各种恶性肿瘤的首位。肺癌可分为小细胞肺癌和非小细胞肺癌，其中非小细胞肺癌占80%以上，主要是由腺癌和鳞癌组成。组织病理学检查是肺癌诊断和分类的金标准，但是当肿瘤分化较差，缺少肺腺癌和肺鳞癌的形态学特征，分型就比较困难。约70-80%的非小细胞肺癌患者就诊时处于晚期，诊断主要依赖于活检或细胞学检查。活检标本或细胞学标本较小，难以了解肿瘤全貌。在非小细胞肺癌中，组织学亚型不明确的约占22%，在小活检标本中，组织学亚型不明确的约占30-50%。因此，非小细胞肺癌亚型的精准判断，对于肿瘤的治疗起到了至关重要的作用。',
					description:'Canhelp-Lung 是一种多基因分子诊断方法，通过检测肿瘤组织中一组特定基因的表达水平来判断肺癌最主要的两个亚型:腺癌和鳞癌。精准的病理分型可以为临床医生提供更准确的信息，为治疗方案的制定提供参考和依据，帮助患者最大程度的从治疗中获益。研究人员对Canhelp-Lung检测的有效性和准确性进行了全面的测试，在两项包含有205例和1130例样本的验证中，检测的准确率分别达到了95%和91%。',
					people:['肿瘤组织分化较差，无法明确类型的非小细胞肺癌患者']
				},
			}
		},
		mounted() {
			var that = this;
			that.show = true;
		}
	}
</script>

<style lang="scss" scoped>
	.myProduct{
		.productMain {
			padding: 2% 15% 5% !important;
			display: flex;
		}
		.leftDetail{
			width: 200px;
			flex-shrink: 0;
			margin-right: 20px;
		}
		.rightDetail{
			width: 100%;
		}
	}
	@media (max-width:1024px){
		.leftDetail{
			display:none !important;
		}
	}
</style>