<template>
	<main class="myTech">
		<Nav />
		<!-- 主要内容 -->
		<transition name="slide-top">
			<div v-if="show" class="techMain">
				<h1>{{$t('tech.require.h1')}}</h1>
				<p class="techP">{{$t('tech.require.p1')}}<span>{{$t('tech.require.p2')}}</span><span>{{$t('tech.require.p3')}}</span></p>
				<div class="requireDiv">
					<el-row>
						<el-col :xs="24" :sm="12" :md="{span: 9, offset: 3}">
							<div class="requireCol">
								<img src="../../assets/images/3_03.jpg">
								<div class="requireDiv1Item">
									<h3>{{$t('tech.require.itme1.h3')}}</h3>
									<p>{{$t('tech.require.itme1.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="{span: 9, offset: 3}">
							<div class="requireCol">
								<img src="../../assets/images/3_04.jpg">
								<div class="requireDiv1Item">
									<h3>{{$t('tech.require.itme2.h3')}}</h3>
									<p>{{$t('tech.require.itme2.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="{span: 9, offset: 3}">
							<div class="requireCol">
								<img src="../../assets/images/3_05.jpg">
								<div class="requireDiv1Item">
									<h3>{{$t('tech.require.itme3.h3')}}</h3>
									<p>{{$t('tech.require.itme3.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="{span: 9, offset: 3}">
							<div class="requireCol">
								<img src="../../assets/images/3_06.jpg">
								<div class="requireDiv1Item">
									<h3>{{$t('tech.require.itme4.h3')}}</h3>
									<p>{{$t('tech.require.itme4.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="{span: 9, offset: 3}">
							<div class="requireCol">
								<img src="../../assets/images/3_07.jpg">
								<div class="requireDiv1Item">
									<h3>{{$t('tech.require.itme5.h3')}}</h3>
									<p>{{$t('tech.require.itme5.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="{span: 9, offset: 3}">
							<div class="requireCol">
								<img src="../../assets/images/3_08.jpg">
								<div class="requireDiv1Item">
									<h3>{{$t('tech.require.itme6.h3')}}</h3>
									<p>{{$t('tech.require.itme6.p')}}</p>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	import Nav from "../nav.vue";	
	export default {
		components:{
			Nav
		},
		data() {
			return {
				activeName: 'first',
				show:false
			}
		},
		mounted() {
			var that=this;
			that.show=true
		}
	}
</script>

<style lang="scss" scoped>

</style>
