<template>
	<main class="myAbout">
		<transition name="slide-top">
			<div v-if="show">
				<div class="policyDiv">
					<!-- banner部分 -->
					<div class="policyBanner">
						<img src="../../assets/images/banner_policy.jpg"/>
						<div class="policyBannerDiv">
							<h1>隐私政策</h1>
							<hr>
							<p>为更好地让您了解我们对个人信息的处理方式，我们制定了《腾讯隐私政策》、《产品隐私保护指引》、《腾讯SDK隐私政策》、《儿童隐私保护声明》以及《Cookie政策》等一系列隐私政策文件。</p>
						</div>
					</div>
					<!-- tab部分 -->
					<div class="policyTab">
						<el-tabs v-model="activeName">
						  <el-tab-pane label="用户管理" name="first"></el-tab-pane>
						  <el-tab-pane label="配置管理" name="second"></el-tab-pane>
						  <el-tab-pane label="角色管理" name="third"></el-tab-pane>
						  <el-tab-pane label="定时任务补偿" name="fourth"></el-tab-pane>
						</el-tabs>
					</div>
					<!-- main部分 -->
					<div class="policyMain">
						<div class="policyMainLeft">
							
						</div>
						<div class="policyMainRight"></div>
					</div>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	export default {
		data(){
			return{
				show:false,
				activeName:"first"
			}
		},
		mounted() {
			var that = this;
			that.show=true;
		},
		methods:{}
	}
</script>

<style lang="scss" scoped>
	.policyDiv{
		.policyBanner{
			position: relative;
			.policyBannerDiv{
				position: absolute;
				top:0px;
				width: 350px;
				margin: 2% 10%;
				color: #ffffff;
				p{
					font-size: 14px;
					line-height: 30px;
				}
			}
		}
		.policyTab{
			border-bottom: 1px solid #d9dce5;
			::v-deep .el-tabs{
				padding: 0 10%;
				.el-tabs__nav-wrap::after{
					background-color:none !important;
				}
				.el-tabs__header{
					margin: 0px !important;
				}
				.el-tabs__item{
					line-height: 70px;
					height: 70px;
					font-size: 18px !important;
				}
			}
		}
		.policyMain{
			padding: 3% 10%;
		}
	}
</style>