<template>
	<main class="myJoin">
		<Nav />
		<transition name="slide-top">
			<div v-if="show" class="jobDiv">
				<div class="jobDiv1">
					<h1>加入你喜欢的事业</h1>
					<h2>Join the career you love</h2>
					<p>你自信飞扬，潇洒英姿，你说你准备好了，世界就在你的脚下，但唯独缺少一个平台！</p>
					<p>——可帮基因欢迎年轻的你</p>
					<p>我们希望你拥有特别的视角，独立思考问题，善于组织各方资源。在每项工作中始终能保持对结果负责的态度和积极向上乐于分享的团队精神。</p>
				</div>
				<div class="jobDiv2">
					<!-- :row-key="getRowKeys" :expand-row-keys="expands" -->
					<el-table ref="table" :data="tableData" style="width: 100%">
						<el-table-column label="职位名称" prop="name">
						</el-table-column>
						<el-table-column label="职位部门" prop="department">
						</el-table-column>
						<el-table-column label="员工学历" prop="require">
						</el-table-column>
						<el-table-column label="工作地点" prop="area">
						</el-table-column>
						<el-table-column label="发布日期" prop="date">
						</el-table-column>
					</el-table>
				</div>
				<div class="jobDiv3">
					<div class="piepeDiv1">
						<img src="../../assets/images/3_19.jpg">
						<p>整体流程</p>
					</div>
					<div class="piepeDiv2">
						<el-row>
							<el-col :xs='24' :sm="24">
								<div>
									<img src="../../assets/images/job/1.png">
									<p>投递简历</p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/jt.png">
									<p></p>
								</div>
								<div>
									<img src="../../assets/images/job/2.png">
									<p>初试</p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/jt.png">
									<p></p>
								</div>
								<div>
									<img src="../../assets/images/job/3.png">
									<p>复试</p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/jt.png">
									<p></p>
								</div>
								<div>
									<img src="../../assets/images/job/4.png">
									<p>Offer</p>
								</div>
							</el-col>
						</el-row>
					</div>
					<p class="sendEmail">
						咨询岗位详情或投递简历，请发送邮件至：<a href="mailto:hr@cancerhelp.cn" translate="JOB_SEND_CV"
							class="sendButton">hr@cancerhelp.cn</a>
					</p>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				show: false,
				currentIndexName: '',
				// // 获取row的key值
				// getRowKeys(row) {
				// 	return row.name;
				// },
				// // 要展开的行，数值的元素是row的key值
				// expands: [],
				tableData: [{
					name: '实验室技术员',
					department: '研发部',
					require: "本科及以上",
					area: '杭州',
					date: '2017.11.16',
				}, {
					name: '文案策划',
					department: '综合部',
					require: "本科及以上",
					area: '杭州',
					date: '2017.11.16',
				}]
			}
		},
		mounted() {
			var that = this;
			that.show = true;
			// this.expands.push(this.tableData[0].name)
		},
		methods: {
			toogleExpand(row) {
				var that = this;
				let $table = that.$refs.table;
				that.tableData.map((item) => {
					if (row.name != item.name) {
						$table.toggleRowExpansion(item, false)
					}
				})
				$table.toggleRowExpansion(row);
				that.currentIndexName = row.name;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.myAbout {
		background: #f0f3f5 !important;
	}

	@media only screen and (max-width: 460px) {
		.piepeDiv2 {
			.el-col {
				display: table-column !important;
			}
		}
	}

	@media only screen and (max-width: 991px) {
		.piepeDiv2 {
			.el-row .el-col {
				div:nth-child(even) {
					display: none;
				}
			}
		}
	}
</style>
