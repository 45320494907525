<template>
	<main class="myProduct">
		<Nav />
		<!-- 主要内容 -->
		<transition name="slide-top">
			<div v-if="show" class="products">
				<h1>{{ $t('product.h1') }}</h1>
				<p class="productP">{{ $t('product.p1') }}<span>{{ $t('product.p2') }}</span><span>{{ $t('product.p3')
				}}</span>
				</p>
				<div class="productDiv">
					<el-row :gutter="90">
						<el-col :xs="24" :sm="12" :md="8">
							<router-link :to="{ path: '/product/panca' }" class="productCol panca">
								<div class="productDivItem">
									<img src="../../assets/images/product/icon_panca.png" />
									<p>Canhelp-Origin</p>
									<p class="name">肿瘤组织起源基因检测</p>
								</div>
								<div class="productDivItem-on-hover">
									<p>Canhelp-Origin</p>
									<p class="name">肿瘤组织起源基因检测</p>
									<div class="icon"></div>
								</div>
							</router-link>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<router-link :to="{ path: '/product/ubca' }" class="productCol ubc">
								<div class="productDivItem">
									<img src="../../assets/images/product/icon_uriblad.png" />
									<p>Canhelp-UriBLAD</p>
									<p class="name">尿路上皮癌基因检测</p>
								</div>
								<div class="productDivItem-on-hover">
									<p>Canhelp-UriBLAD</p>
									<p class="name">尿路上皮癌基因检测</p>
									<div class="icon"></div>
								</div>
							</router-link>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<router-link :to="{ path: '/product/coo' }" class="productCol coo">
								<div class="productDivItem">
									<img src="../../assets/images/product/icon_coo.png" />
									<p>Canhelp-COO</p>
									<p class="name">弥漫性大B细胞淋巴瘤起源分型基因检测</p>
								</div>
								<div class="productDivItem-on-hover">
									<p>Canhelp-COO</p>
									<p class="name">弥漫性大B细胞淋巴瘤起源分型基因检测</p>
									<div class="icon"></div>
								</div>
							</router-link>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<router-link :to="{ path: '/product/mb' }" class="productCol mb">
								<div class="productDivItem">
									<img src="../../assets/images/product/icon_mb.png" />
									<p>Canhelp-MB</p>
									<p class="name">髓母细胞瘤分子分型检测</p>
								</div>
								<div class="productDivItem-on-hover">
									<p>Canhelp-MB</p>
									<p class="name">髓母细胞瘤分子分型检测</p>
									<div class="icon"></div>
								</div>
							</router-link>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<router-link :to="{ path: '/product/lung' }" class="productCol lung">
								<div class="productDivItem">
									<img src="../../assets/images/product/icon_lung.png" />
									<p>Canhelp-Lung</p>
									<p class="name">肺癌基因检测</p>
								</div>
								<div class="productDivItem-on-hover">
									<p>Canhelp-Lung</p>
									<p class="name">肺癌基因检测</p>
									<div class="icon"></div>
								</div>
							</router-link>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<router-link :to="{ path: '/product/Kidney' }" class="productCol kidney">
								<div class="productDivItem">
									<img src="../../assets/images/product/icon_kidney.png" />
									<p>Canhelp-Kidney</p>
									<p class="name">肾细胞癌基因检测</p>
								</div>
								<div class="productDivItem-on-hover">
									<p>Canhelp-Kidney</p>
									<p class="name">肾细胞癌基因检测</p>
									<div class="icon"></div>
								</div>
							</router-link>
						</el-col>
					</el-row>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
import Nav from "../nav.vue";
export default {
	components: {
		Nav
	},
	data() {
		return {
			activeName: 'first',
			show: false
		}
	},
	mounted() {
		var that = this;
		that.show = true
	}
}
</script>

<style lang="scss">
.myProduct {
	background: #F5F5F5;
}

.products {
	margin-bottom: 0px !important;
}


.productDiv {
	margin-bottom: 0px !important;
	font-weight: bold;

	.el-row {
		padding-bottom: 5% !important;

		a.productCol {
			text-decoration: none;
			margin-bottom: 2rem;
			box-shadow: 0 0 20px 5px rgb(0 0 0 / 10%);
			display: block;
			padding-bottom: 1rem;
			position: relative;

			.productDivItem-on-hover {
				display: none;
			}
		}

		a.productCol:hover {
			background-size: 100% 100%;
			background-position: 0% 0%;
			background-repeat: no-repeat;

			.productDivItem {
				visibility: hidden;
			}

			.productDivItem-on-hover {
				display: block;
			}

			.productDivItem-on-hover p {
				font-size: 20px;
				color: #FFF;
				font-weight: bold;
				position: absolute;
				width: 100%;
				top: 40%;
			}

			.productDivItem-on-hover p.name {
				top: 50%;
			}

			.productDivItem-on-hover .icon {
				background-image: url('../../assets/images/product/icon_select.png');
				position: absolute;
				width: 60px;
				height: 60px;
				top: 65%;
				left: 50%;
				margin-left: -30px;
				background-size: 100%;
			}

			.productDivItem-on-hover .icon:hover {
				background-image: url('../../assets/images/product/icon_select_hover.png');
			}
		}

		a.productCol.panca:hover {
			background-image: url('../../assets/images/product/icon_bg_panca.png');
		}

		a.productCol.ubc:hover {
			background-image: url('../../assets/images/product/icon_bg_uriblad.png');
		}

		a.productCol.coo:hover {
			background-image: url('../../assets/images/product/icon_bg_coo.png');
		}

		a.productCol.mb:hover {
			background-image: url('../../assets/images/product/icon_bg_mb.png');
		}

		a.productCol.kidney:hover {
			background-image: url('../../assets/images/product/icon_bg_kidney.png');
		}

		a.productCol.lung:hover {
			background-image: url('../../assets/images/product/icon_bg_lung.png');
		}


		img {
			width: 100%;
		}

		p {
			color: #0472BF;
			font-size: 14px;
		}
	}
}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 500px) {}</style>
