<template>
	<transition name="slide-top">
	<div v-if="show" class="container">
		<!-- 走马灯 -->
		<div class="myCarousel">
			<el-carousel :height="imgHeight+'px'" trigger="click" :interval="5000" arrow="always">
				<el-carousel-item class="carouselDiv">
					<img ref="image" src="../assets/images/home_slide_22.jpg" alt="创新基因科技，帮助癌症患者">
					<!-- <h1>{{$t('home.banner.banner2')}}</h1> -->
				</el-carousel-item>
				<el-carousel-item class="carouselDiv">
					<img ref="image" src="../assets/images/MSI- banner.jpg" alt="Fudan CUP-001">
					<!-- <h1>{{$t('home.banner.banner2')}}</h1> -->
				</el-carousel-item>
				<el-carousel-item class="carouselDiv">
					<img ref="image" src="../assets/images/home_slide_1.jpg" alt="让医生有力量，让患者有希望">
					<h1>{{$t('home.banner.banner1')}}
					    <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{$t('home.banner.banner11')}}
					</h1>
				</el-carousel-item>
				<el-carousel-item class="carouselDiv">
					<img ref="image" src="../assets/images/home_slide_33.jpg" alt="肿瘤组织起源基因检测产品 Canhelp-Origin Assay">
					<h1>{{$t('home.banner.banner3')}}
						<br>{{$t('home.banner.banner31')}}
					</h1>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 我们优势 -->
		<div class="myAdvantages">
			<el-row class="advaBox">
				<el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8" class="advaBoxLeft">
					<div class="advaLeft">
						<div class="advaLeftTitle">
							<p>01/{{$t('home.advantages.top1.p')}}</p>
							<p>{{$t('home.advantages.top1.text')}}</p>
						</div>
						<div>
							<img src="../assets/images/1_08.png">
						</div>
					</div>
					<div class="advaLeft">
						<div class="advaLeftTitle">
							<p>02/{{$t('home.advantages.top2.p')}}</p>
							<p>{{$t('home.advantages.top2.text')}}</p>
						</div>
						<div>
							<img src="../assets/images/1_09.png">
						</div>
					</div>
					<div class="advaLeft">
						<div class="advaLeftTitle">
							<p>03/{{$t('home.advantages.top3.p')}}</p>
							<p>{{$t('home.advantages.top3.text')}}</p>
						</div>
						<div>
							<img src="../assets/images/1_10.png">
						</div>
					</div>
				</el-col>
				<el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8" class="hidden-md-and-down advaBoxCenter">
					<div class="advaCenter">
						<div>
							<img src="../assets/index_02.png">
						</div>
						<div>
							<img src="../assets/adva_02.png">
							<p>{{$t('home.advantages.center')}}</p>
						</div>
					</div>
				</el-col>
				<el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8" class="advaBoxRight">
					<div class="advaRight">
						<div class="advaRightTitle">
							<p>04/{{$t('home.advantages.top4.p')}}</p>
							<p>{{$t('home.advantages.top4.text')}}</p>
						</div>
						<div>
							<img src="../assets/images/1_11.png">
						</div>
					</div>
					<div class="advaRight">
						<div class="advaRightTitle">
							<p>05/{{$t('home.advantages.top5.p')}}</p>
							<p>{{$t('home.advantages.top5.text')}}</p>
						</div>
						<div>
							<img src="../assets/images/1_12.png">
						</div>
					</div>
					<div class="advaRight">
						<div class="advaRightTitle">
							<p>06/{{$t('home.advantages.top6.p')}}</p>
							<p>{{$t('home.advantages.top6.text')}}</p>
						</div>
						<div>
							<img src="../assets/images/main_ss_06.png">
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- 我们产品 -->
		<div class="myProducts">
			<el-row>
				<el-col :xs="12" :sm="8" :md="{span: '4-8'}" class="myProductsMin">
					<router-link :to="{path:'/product/panca'}" class="myProductsMin">
						<div class="productsDiv">
							<img class="normalImg" src="../assets/images/home/icon_panca.png">
							<img class="activeImg" src="../assets/images/home/icon_panca_w.png">
							<h3>Canhelp-Origin</h3>
							<p>肿瘤组织起源基因检测</p>
						</div>
					</router-link>
				</el-col>
				<el-col :xs="12" :sm="8" :md="{span: '4-8'}" class="myProductsMin">
					<router-link :to="{path:'/product/ubca'}" class="myProductsMin">
						<div class="productsDiv">
							<img class="normalImg" src="../assets/images/home/icon_ubc.png">
							<img class="activeImg" src="../assets/images/home/icon_ubc_w.png">
							<h3>Canhelp-UriBLAD</h3>
							<p>尿路上皮癌基因检测</p>
						</div>
					</router-link>
				</el-col>
				<el-col :xs="12" :sm="8" :md="{span: '4-8'}" class="myProductsMin">
					<router-link :to="{path:'/product/coo'}" class="myProductsMin">
						<div class="productsDiv">
							<img class="normalImg" src="../assets/images/home/icon_coo.png">
							<img class="activeImg" src="../assets/images/home/icon_coo_w.png">
							<h3>Canhelp-COO</h3>
							<p>弥漫性大B细胞淋巴瘤<br>起源分型基因检测</p>
						</div>
					</router-link>
				</el-col>
				<el-col :xs="12" :sm="8" :md="{span: '4-8'}" class="myProductsMin">
					<router-link :to="{path:'/product/mb'}" class="myProductsMin">
						<div class="productsDiv">
							<img class="normalImg" src="../assets/images/home/icon_mb.png">
							<img class="activeImg" src="../assets/images/home/icon_mb_w.png">
							<h3>Canhelp-MB</h3>
							<p>髓母细胞瘤分型基因检测</p>
						</div>
					</router-link>
				</el-col>
				
				<!-- <el-col :xs="12" :sm="8" :md="{span: '4-8'}" class="myProductsMin">
					<router-link :to="{path:'/product/lung'}" class="myProductsMin">
						<div class="productsDiv">
							<img class="normalImg" src="../assets/images/home/icon_lung.png">
							<img class="activeImg" src="../assets/images/home/icon_lung_w.png">
							<h3>Canhelp-Lung</h3>
							<p>{{$t('home.product.product3')}}</p>
						</div>
					</router-link>
				</el-col>
				<el-col :xs="12" :sm="8" :md="{span: '4-8'}" class="myProductsMin">
					<router-link :to="{path:'/product/kidney'}" class="myProductsMin">
						<div class="productsDiv">
							<img class="normalImg" src="../assets/images/home/icon_kidney.png">
							<img class="activeImg" src="../assets/images/home/icon_kidney_w.png">
							<h3>Canhelp-Kidney</h3>
							<p>{{$t('home.product.product2')}}</p>
						</div>
					</router-link>
				</el-col> -->
				<el-col :xs="12" :sm="8" :md="{span: '4-8'}" class="myProductsMin">
					<router-link :to="{path:'/product'}" class="myProductsMin">
						<div class="productsDiv">
							<img class="normalImg" src="../assets/images/home/icon_other.png">
							<img class="activeImg" src="../assets/images/home/icon_other_w.png">
							<h3>其他产品</h3>
							<p>更多...</p>
						</div>
					</router-link>
				</el-col>
			</el-row>
		</div>
		<!-- 可帮介绍canhelp genomics -->
		<div class="myIntrouction">
			<h1>Canhelp Genomics</h1>
			<p>
				{{$t('home.canhelp.p')}}
			</p>
			<router-link class="lookmore" :to="{path:'/about/introduction'}">{{$t('home.canhelp.more')}}<i class="el-icon-sort-up"></i></router-link>
		</div>
		<!-- 合作伙伴 -->
		<div class="myCooperation">
			<h2>{{$t('home.cooperation.partnersH2')}}</h2>
			<h1>{{$t('home.cooperation.partnersH1')}}</h1>
			<el-row class="myCooperationRow" :gutter="20">
				<el-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" v-for="item in 8" :key="item" class="myCooperationDiv">
					<img :src="require('../assets/images/partner_main/'+item+'.png')" alt="">
				</el-col>
			</el-row>
			<router-link class="lookmore" :to="{path:'/about/partner'}">{{$t('home.canhelp.more')}}<i class="el-icon-sort-up"></i></router-link>
		</div>
		<!-- 新闻动态 -->
		<div class="myNews">
			<h2>{{$t('home.new.newsH2')}}</h2>
			<h1>{{$t('home.new.newsH1')}}</h1>
			<el-row :gutter="30" class="myNewsRow">
				<el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8" v-for="(item,index) in $i18n.locale=='zh'?zhNews:enNews" :key="index">
					<router-link class="newsCon" :to="{path:'/newsinfo', query:{id:item.id,key:1}}">
						<!-- <img :src="`${imgUrl}/`+`${item.skin}`"> -->
						<img :src="`${item.skin}`">
						<div class="newsConP">
							<p>{{item.title}}</p>
						</div>
						<div class="newsConText">
							<p>
								{{item.summary}}
							</p>
							<p><span>&nbsp;</span>{{item.date}}</p>
						</div>
					</router-link>
				</el-col>
			</el-row>
		</div>
		<el-dialog title="肿瘤组织起源基因检测公益项目申请" custom-class="feedback-dialog" :visible.sync="feedback.editable"
			center :append-to-body="true" :fullscreen="feedback.fullscreen">
			<el-form label-width="80px" :model="feedback" :rules="rules" ref="feedbackForm" :size="feedback.size">
				<el-form-item label="医疗机构" prop="org">
					<el-input v-model="feedback.org" maxlength="50" placeholder="请输入您的医疗机构名称"></el-input>
				</el-form-item>
				<el-form-item label="您的姓名" prop="name">
					<el-input v-model="feedback.name" maxlength="20" placeholder="请输入您的姓名"></el-input>
				</el-form-item>
				<el-form-item label="联系方式" prop="contact">
					<el-input v-model="feedback.contact" maxlength="50" placeholder="请输入您的联系方式（电话或微信）"></el-input>
				</el-form-item>
				<el-form-item label="产品需求" prop="comment">
					<el-input type="textarea" :autosize="{minRows: 3, maxRows: 5}" v-model="feedback.comment"
					 maxlength="500" placeholder="请输入您的产品使用需求" show-word-limit></el-input>
				</el-form-item>
				<div class="tips"><span>欢迎您关注可帮基因微信公众号，<br/>及时了解肿瘤组织起源检测最新进展。</span></div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="feedback.editable=false">取消</el-button>
				<el-button type="primary" @click="submitFeedback()">提交</el-button>
			</span>
		</el-dialog>
	</div>
	</transition>
</template>
<script>
	export default {
		data() {
			return {
				// 走马灯轮播图片高
				imgHeight: "",
				newsInfo: [],
				show:false,
				zhNews:[],
				enNews:[],
				feedback: {
					editable: false,
					org: null,
					name: null,
					contact: null,
					comment: null,
					fullscreen: false,
					size: 'medium'
				},
				rules: {
					org: [{
						required: true,
						message: "请输入您的医疗机构名称",
						trigger: "change"
					}],
					name: [{
						required: true,
						message: "请输入您的姓名",
						trigger: "change"
					}],
					contact: [{
						required: true,
						message: "请输入您的联系方式",
						trigger: "change"
					}],
					comment: [{
						required: true,
						message: "请输入您的产品使用需求",
						trigger: "change"
					}]
				}
			}
		},
		mounted() {
			var that = this;
			that.show=true;
			setTimeout(function() {
				that.imgHeight = document.body.clientWidth * 2 / 5;
				if (document.body.clientWidth < 600) {
					that.feedback.fullscreen = true;
					that.feedback.size = 'small';
				}
			}, 50);
			// 监听窗口变化，使得轮播图高度自适应图片高度
			window.addEventListener("resize", () => {
				that.imgHeight = document.body.clientWidth * 2 / 5;
				
				if (document.body.clientWidth < 600) {
					that.feedback.fullscreen = true;
					that.feedback.size = 'small';
				} else {
					that.feedback.fullscreen = false;
					that.feedback.size = 'medium';
				}
			});
			that.newsData();
			var qs = this.$route.query;
			if (qs && qs.feedback) {
				this.feedback.editable = true;
			}
		},
		methods: {
			newsData() {
				var that = this;
				that.$axios.get("/api/news/newsTop3").then(function(res) {
					that.zhNews = res.data.data
				}).catch(function(error) {})
				// that.$axios.get("/api/engNews/newsTop3").then(function(res) {
				// 	that.enNews = res.data.data
				// }).catch(function(error) {})
			},
			submitFeedback() {
				this.$refs["feedbackForm"].validate((valid) => {
					if (valid) {
						var that = this;
						that.$axios.post("/api/feedback", that.feedback).then(function(res) {
							that.feedback.editable = false;
							this.$notify({
								title: '成功',
								message: '您的申请已收到，我们将尽快与您联系。',
								type: 'success'
							});
						}).catch(function(error) {
							console.log(error);
						});
					}
				});
			}
		}
	}
</script>
<style lang="scss" scoped>
	// 反馈
	.feedback-dialog .tips {
		text-align: center;
		width: 100%;
	}

	// 走马灯样式
	.myCarousel {
		margin-top: 4rem;

		.el-carousel__item img {
			width: 100%;
			// height: 100%;
			object-fit: cover;
		}

		.el-carousel__item:nth-child(2n) {
			background-color: #99a9bf;
		}

		.el-carousel__item:nth-child(2n+1) {
			background-color: #d3dce6;
		}

		.carouselDiv {
			color: #0176b2;

			h1 {
				font-size: 1.6rem;
				position: absolute;
				top: 24%;
				left: 5%;
				font-weight: normal;
			}
		}

	}

	// 我们优势
	.myAdvantages {
		background: url(../assets/index_01.jpg);
		background-size: cover;

		// el-row样式
		.advaBox {
			display: flex;
			box-sizing: border-box;
			padding: 6% 7% 4%;
		}

		// 左侧一列
		.advaBoxLeft {
			display: flex;
			flex-direction: column;
			min-height: 400px;

			.advaLeft {
				flex: 1;
				display: flex;
				justify-content: flex-end;
				font-size: 0.8rem;
				color: #666666;

				.advaLeftTitle {
					margin-right: 20px;
					text-align: right;
					line-height: 1.2rem;

					p:first-child {
						font-size: 1rem;
						margin-bottom: 5px;
						line-height: 1.6rem;
					}
				}

				img {
					width: 2.5rem;
				}
			}

			.advaLeft:nth-child(2) {
				margin-right: 20%;
			}
		}

		// 中间一列
		.advaBoxCenter {
			.advaCenter {
				position: relative;
				text-align: center;
				margin: 0 auto;

				div:first-child {
					img {
						width: 94%;
						height: auto;
						animation: rotation 5s linear infinite;
						-moz-animation: rotation 5s linear infinite;
						-webkit-animation: rotation 5s linear infinite;
						-o-animation: rotation 5s linear infinite;
					}
				}

				div:last-child {
					position: absolute;
					top: 25%;
					width: 100%;

					img {
						height: auto;
					}

					p {
						color: #666666;
						font-size: 20px;
						line-height: 22px;
						margin-top: 5%;
						padding-bottom: 10px;
						position: relative;
					}

					p:after {
						margin: 0 auto;
						text-align: center;
						content: '';
						position: absolute;
						top: 35px;
						left: 0;
						right: 0;
						height: 1px;
						width: 30px;
						background-color: #0077b5;
					}
				}
			}
		}

		// 右侧一列
		.advaBoxRight {
			display: flex;
			flex-direction: column;
			min-height: 400px;

			.advaRight {
				flex: 1;
				display: flex;
				justify-content: flex-start;
				font-size: 0.8rem;
				color: #666666;

				.advaRightTitle {
					margin-left: 20px;
					line-height: 1.2rem;
					order: 2;

					p:first-child {
						font-size: 1rem;
						margin-bottom: 5px;
						line-height: 1.6rem;
					}
				}

				img {
					width: 2.5rem;
				}
			}

			.advaRight:nth-child(2) {
				margin-left: 20%;
			}
		}

	}

	// 我们的产品
	.myProducts {
		background: #FFFFFF;
		// display: flex;
		// align-items: center;
		// justify-content: space-between;

		.el-row {
			display: flex;
			flex-wrap: wrap;
		}

		.myProductsMin {
			color: #333333;
			text-align: center;
			height: 100%;
			// border-right: 1px solid #EAEAEA;
			text-decoration: none;

			.productsDiv {
				box-sizing: border-box;
				padding: 50% 0;
				height: 100%;
				transition: padding 0.5s;
				-moz-transition: padding 0.5s;
				/* Firefox 4 */
				-webkit-transition: padding 0.5s;
				/* Safari and Chrome */
				-o-transition: padding 0.5s;

				/* Opera */
				.normalImg {
					display: inline-block;
				}

				.activeImg {
					display: none;
				}

				img {
					width: 32%;
				}

				h3 {
					margin: 8% auto 5%;
					font-size: 1.2rem;
				}

				p {
					color: #666666;
					font-size: 0.9rem;
				}
			}
		}

		.myProductsMin:hover {
			color: #FFFFFF !important;
			background: url(../assets/images/1_21.jpg) no-repeat;
			cursor: pointer;

			.productsDiv {
				padding: 48% 0 52%;

				.normalImg {
					display: none;
				}

				.activeImg {
					display: inline-block;
				}

				p {
					color: #FFFFFF;
				}
			}
		}
	}

	// 可帮介绍
	.myIntrouction {
		background: url(../assets/images/1_22.jpg) no-repeat;
		background-size: cover;
		padding: 5% 7%;
		text-align: center;

		h1 {
			color: #0077b5;
			font-size: 2.2rem;
			font-weight: normal;
		}

		p {
			margin: 4% auto;
			font-size: 0.8rem;
			color: #666666;
			line-height: 1.6rem;
		}

	}

	// 合作伙伴
	.myCooperation {
		background: url(../assets/images/1_25.jpg) no-repeat;
		background-size: cover;
		padding: 5% 7%;
		text-align: center;
		color: #FFFFFF;

		h1 {
			font-size: 2rem;
			line-height: 2.5rem;
			font-weight: normal;
		}

		h2 {
			font-size: 1.6rem;
			line-height: 2rem;
			font-weight: normal;
		}

		.myCooperationRow {
			margin: 4% auto;

			.myCooperationDiv img {
				width: 70%;
			}

			.myCooperationDiv img:hover {
				cursor: pointer;
			}
		}
	}

	// 新闻动态
	.myNews {
		padding: 0.5% 7% 5%;
		background-color: #FFFFFF;
		box-sizing: border-box;
		text-align: center;

		h1 {
			font-size: 2rem;
			line-height: 2.5rem;
			font-weight: normal;
			color: #CCCCCC;
		}

		h2 {
			font-size: 1.6rem;
			line-height: 2rem;
			font-weight: normal;
			color: #333333;
		}

		.myNewsRow {
			margin: 3% auto 0px;
			display: flex;
			flex-wrap: wrap;

			.newsCon {
				display: block;
				text-decoration: none;
				color: #666666;
				margin: 4% 0;
				// height: 100%;
				position: relative;
				top: 0px;
				transition: all 0.5s;
				-moz-transition: all 0.5s;
				/* Firefox 4 */
				-webkit-transition: all 0.5s;
				/* Safari and Chrome */
				-o-transition: all 0.5s;

				/* Opera */
				img {
					width: 100%;
					// width: auto;
					height: 15rem;
					object-fit: cover;
				}

				.newsConP {
					background: rgba(0, 0, 0, 0.4);
					margin-top: -2.5rem;
					width: 90%;
					position: relative;
					height: 2.25rem;
					// line-height: 2.25rem;

					p {
						position: absolute;
						padding: 0 0.5rem;
						width: 95%;
						bottom: 0px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: left;
						color: #FFFFFF;
						font-size: 0.7rem;
					}
				}

				.newsConText {
					padding: 1rem;

					p {
						text-align: left;
						font-size: 0.7rem;
						color: #666666;
						line-height: 1.3rem;

						span {
							background-color: #01AAB4;
							width: 10px;
							height: 2px;
							vertical-align: middle;
							margin-right: 10px;
							display: inline-block;
						}
					}

					p:first-child {
						display: -webkit-box;
						overflow: hidden;
						-webkit-line-clamp: 2; //显示3行
						-webkit-box-orient: vertical;
					}
				}
			}

			.newsCon:hover {
				cursor: pointer;
				box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
				text-decoration: underline;
				position: relative;
				top: -10px;
			}

		}
	}

	//我们优势中间圈图的动画
	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	@-moz-keyframes rotation {
		from {
			-moz-transform: rotate(0deg);
		}

		to {
			-moz-transform: rotate(360deg);
		}
	}

	@-webkit-keyframes rotation {
		from {
			-webkit-transform: rotate(0deg);
		}

		to {
			-webkit-transform: rotate(360deg);
		}
	}

	@media screen and (max-width: 1199px) {

		// 我们优势屏幕小于1200时
		.advaLeft {
			justify-content: flex-start !important;
		}

		.advaLeftTitle {
			order: 2;
			text-align: left !important;
			margin-left: 20px;
		}

		.advaRight {
			justify-content: flex-start !important;
		}

		.advaBoxRight .advaRight:nth-child(2) {
			margin-left: 0px !important;
		}
	}
	@media only screen and (min-width:992px) {
		.el-col-md-4-8 {
			width: 20%;
		}
	}
</style>
