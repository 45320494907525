<template>
	<main class="myTech">
		<Nav />
		<!-- 主要内容 -->
		<transition name="slide-top">
			<div v-if="show" class="techMain">
				<h1>{{$t('tech.achievements.h1')}}</h1>
				<p class="techP">
					{{$t('tech.achievements.p1')}}<span>{{$t('tech.achievements.p2')}}</span><span>{{$t('tech.achievements.p3')}}</span>
				</p>
				<div class="achieveDiv">
					<p v-html="$t('tech.achievements.text')"></p>
					<el-collapse class="myCollapse" v-model="activeName" accordion>
						<el-collapse-item v-for="(item,index) in achievement" :title="String(item.year)"
							:name="index+1">
							<ol>
								<li v-for="itemsub in item.items"  @click="blankLink(itemsub.link)">
									<!-- {{itemsub.content}} -->
									<div class="col_left_1">
										{{itemsub.title}}
									</div>
									<div class="col_left_2">
										{{itemsub.authors}}
									</div>
									<div class="col_left_3">
										<span>{{itemsub.journal}}</span> &nbsp;<span>{{itemsub.volumePages}}</span>
									</div>
								</li>
							</ol>
						</el-collapse-item>
					</el-collapse>
				</div>
			</div>
		</transition>

	</main>
</template>

<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				activeName: 1,
				show: false,
				achievement: []
			}
		},
		mounted() {
			var that = this;
			that.show = true
			that.getData();
		},
		methods: {
			// 跳转外部链接
			blankLink(url){
				var that=this;
				window.open(url, '_blank');
			},
			getData() {
				var that = this;
				that.$axios.get("/api/publications").then(function(res) {
					that.achievement = res.data.data
				}).catch(function(error) {})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.achieveDiv {
		li{
			cursor: pointer;
		}
		li::marker {
			font-weight: bold !important;
		}

		.col_left_1 {
			font-weight: bold;
			font-size: 1.25em;
			line-height: 1.5;
		}

		.col_left_2 {
			margin-bottom: 0.5em;
			color: #666;
			line-height: 1.5;
			margin-top: 1em;
		}

		.col_left_3 {
			.span {
				display: inline-block;
				color: #666;
				margin-right: 1.5em
			}

			span:nth-child(1) {
				font-weight: bold;
			}
		}
	}
</style>