<template>
	<main class="myTech">
		<Nav />
		<!-- 主要内容 -->
		<transition name="slide-top">
			<div v-if="show" class="techMain">
				<h1>{{$t('tech.pipeline.h1')}}</h1>
				<p class="techP">{{$t('tech.pipeline.p1')}}<span>{{$t('tech.pipeline.p2')}}</span><span>{{$t('tech.pipeline.p3')}}</span></p>
				<div class="piepeDiv">
					<div class="piepeDiv1">
						<img src="../../assets/images/3_19.jpg">
						<p>{{$t('tech.pipeline.itme1.p')}}</p>
					</div>
					<div class="piepeDiv2">
						<el-row>
							<el-col :xs='24' :sm="24" :md="{span: 19, offset: 5}">
								<div>
									<img src="../../assets/images/pipeline/11.png">
									<p>{{$t('tech.pipeline.itme1.top1')}}</p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/jt.png">
									<p></p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/12.png">
									<p>{{$t('tech.pipeline.itme1.top2')}}</p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/jt.png">
									<p></p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/13.png">
									<p>{{$t('tech.pipeline.itme1.top3')}}</p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/jt.png">
									<p></p>
								</div>
								<div>
									<img src="../../assets/images/pipeline/14.png">
									<p>{{$t('tech.pipeline.itme1.top4')}}</p>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="piepeDiv3">
						<ul>
							<li>
								<div class="liStyle">
									<span>Canhelp-Origin</span>
									<el-progress :text-inside="true" :percentage="100">
									</el-progress>
								</div>
							</li>
							<li>
								<div class="liStyle">
									<span>Canhelp-UriBLAD</span>
									<el-progress :text-inside="true" :percentage="70">
									</el-progress>
								</div>
							</li>
							<li>
								<div class="liStyle">
									<span>Canhelp-COO</span>
									<el-progress :text-inside="true" :percentage="40">
									</el-progress>
								</div>
							</li>
							<li>
								<div class="liStyle">
									<span>Canhelp-MB</span>
									<el-progress :text-inside="true" :stroke-width="14" :percentage="40" status="warning">
									</el-progress>
								</div>
							</li>
							<li>
								<div class="liStyle">
									<span>Canhelp-Lung</span>
									<el-progress :text-inside="true" :percentage="40" status="warning">
									</el-progress>
								</div>
							</li>
							<li>
								<div class="liStyle">
									<span>Canhelp-Kidney</span>
									<el-progress :text-inside="true" :percentage="40">
									</el-progress>
								</div>
							</li>
						</ul>
					</div>
				    <div class="piepeDiv4">
				    	<img src="../../assets/images/3_27.jpg">
				    	<p>{{$t('tech.pipeline.itme2.p')}}</p>
				    </div>
					<div class="piepeDiv5">
						<el-row :gutter="60">
							<el-col :xs='12' :sm="8" :md="8">
								<div class="div5">
									<img src="../../assets/images/3_28.jpg">
								</div>
								<p class="p1">{{$t('tech.pipeline.itme2.label1.tip')}}</p>
								<p>{{$t('tech.pipeline.itme2.label1.p')}}</p>
							</el-col>
							<el-col :xs='12' :sm="8" :md="8">
								<div class="div5">
									<img src="../../assets/images/3_29.jpg">
								</div>
								<p class="p1">{{$t('tech.pipeline.itme2.label2.tip')}}</p>
								<p v-html="$t('tech.pipeline.itme2.label2.p')"></p>
							</el-col>
							<el-col :xs='12' :sm="8" :md="8">
								<div class="div5">
									<img src="../../assets/images/3_30.jpg">
								</div>
								<p class="p1">{{$t('tech.pipeline.itme2.label3.tip')}}</p>
								<p v-html="$t('tech.pipeline.itme2.label3.p')"></p>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</transition>
		
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				activeName: 'first',
				show:false
			}
		},
		mounted() {
			var that=this;
			that.show=true;
		}
	}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 991px){
	.piepeDiv2{
		.el-row .el-col{
			div:nth-child(even){
				display: none;
			}
		}
	}
}
</style>
