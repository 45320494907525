<template>
	<main class="myProduct">
		<Nav />
		<!-- 主要内容 -->
		<transition name="slide-top">
			<div v-if="show" class="productMain">
				<el-row>
					<el-col :xs="8" :sm="6">
						<el-tree ref="tree" accordion :data="dataTree" node-key="id" :props="defaultProps"
							@node-click="handleNodeClick">
						</el-tree>
					</el-col>
					<el-col :xs="16" :sm="18" class="rightCon">
						<!-- PanCA -->
						<div v-show="idShow=='1'">
							<div class="rightCon1">
								<p class="title">{{introJson.panCA.title1}}</p>
								<p v-html="introJson.panCA.p11"></p>
								<ul>
									<li v-for="item in introJson.panCA.ul1" v-html="item"></li>
								</ul>
								<p v-html="introJson.panCA.p12"></p>
							</div>
							<div class="rightCon2">
								<p class="title">{{introJson.panCA.title2}}</p>
								<p v-html="introJson.panCA.p21"></p>
								<p v-html="introJson.panCA.p22"></p>
							</div>
						</div>
						<!-- 患者须知 -->
						<div v-show="idShow=='2'">
							<div class="rightCon1">
								<p class="title">{{introJson.patient.title1}}</p>
								<p v-html="introJson.patient.p1"></p>
							</div>
							<div class="rightCon2">
								<p class="title">{{introJson.patient.title2}}</p>
								<p v-html="introJson.patient.p2"></p>
							</div>
							<div class="rightCon3">
								<p class="title">{{introJson.patient.title3}}</p>
							</div>
						</div>
						<div v-show="idShow=='21'">
							<div class="rightCon1">
								<p class="title">{{introJson.patient.patient1.title1}}</p>
								<p v-html="introJson.patient.patient1.p1"></p>
								<ul class="patient21">
									<li v-for="item in introJson.patient.patient1.ul1">{{item}}</li>
								</ul>
								<p v-html="introJson.patient.patient1.p2"></p>
							</div>
						</div>
						<div v-show="idShow=='22'">
							<div class="rightCon1">
								<p class="title">{{introJson.patient.patient2.title1}}</p>
								<ul>
									<li v-for="item in introJson.patient.patient2.ul1" v-html="item"></li>
								</ul>
								<img src="../../assets/images/panca/patient_sub_1.jpg" />
								<ul>
									<li v-for="item in introJson.patient.patient2.ul2" v-html="item"></li>
								</ul>
							</div>
						</div>
						<!-- 医生须知 -->
						<div v-show="idShow=='3'">
							<div class="rightCon1">
								<p class="title">{{introJson.doctor.title1}}</p>
								<p v-html="introJson.doctor.p1"></p>
								<img class="doctorImg" src="../../assets/images/panca/panca_doctor.png" />
							</div>
						</div>
						<div v-show="idShow=='31'">
							<div class="rightCon1">
								<p class="title">{{introJson.doctor.doctor1.title1}}</p>
								<p v-html="introJson.doctor.doctor1.p11"></p>
								<ul class="doctorul">
									<li v-for="item in introJson.doctor.doctor1.ul1" v-html="item"></li>
								</ul>
								<p v-html="introJson.doctor.doctor1.p12"></p>
								<img src="../../assets/images/panca/doctor_sub_1_1.png" />
							</div>
							<div class="rightCon2">
								<p class="title">{{introJson.doctor.doctor1.title2}}</p>
								<p v-html="introJson.doctor.doctor1.p2"></p>
								<ul>
									<li v-for="item in introJson.doctor.doctor1.ul2" v-html="item"></li>
								</ul>
							</div>
							<div class="rightCon3">
								<p class="title">{{introJson.doctor.doctor1.title3}}</p>
								<p v-html="introJson.doctor.doctor1.p3"></p>
							</div>
							<div class="rightCon4">
								<p class="title">{{introJson.doctor.doctor1.title4}}</p>
								<img src="../../assets/images/panca/doctor_sub_1_2.png" />
							</div>
						</div>
						<div v-show="idShow=='32'">
							<div class="rightCon1">
								<p class="title">{{introJson.doctor.doctor2.title1}}</p>
								<p v-html="introJson.doctor.doctor2.p1"></p>
								<img src="../../assets/images/panca/doctor_sub_2.png" />
							</div>
						</div>
						<div v-show="idShow=='33'">
							<div class="rightCon1">
								<img src="../../assets/images/panca/doctor_sub_3.jpg" />
							</div>
						</div>
						<div v-show="idShow=='34'">
							<div class="rightCon1">
								<p class="title">{{introJson.doctor.doctor4.title1}}</p>
								<img class="doctorImg" src="../../assets/images/panca/doctor_sub_4_1.png" />
								<p v-html="introJson.doctor.doctor4.p11"></p>
								<img class="doctorImg" src="../../assets/images/panca/doctor_sub_4_2.png" />
								<p v-html="introJson.doctor.doctor4.p12"></p>
								<a class="ahref" target="_blank" href="http://jco.ascopubs.org/cgi/doi/10.1200/JCO.2012.43.3755">http://jco.ascopubs.org/cgi/doi/10.1200/JCO.2012.43.3755</a>
							</div>
						</div>
						<div v-show="idShow=='35'">
							<div class="rightCon1">
								<p class="title">{{introJson.doctor.doctor5.title1}}</p>
								<p v-html="introJson.doctor.doctor5.p1"></p>
							</div>
						</div>
						<!-- 其他常见问题 -->
						<div v-show="idShow=='4'">
							<div class="rightCon1">
								<el-collapse v-model="activeName" accordion  class="collapseDiv">
								  <el-collapse-item :title="item.title" :name="index" v-for="(item,index) in introJson.other.collapse">
								    <div>
										<p v-html="item.text"></p>
										<ul v-show="item.ul">
											<li v-for="itemli in item.ul" v-html="itemli"></li>
										</ul>
										<p v-show="item.p" v-html="item.p"></p>
									</div>
								  </el-collapse-item>
								</el-collapse>
							</div>
						</div>
						<!-- 我要订购 -->
						<div v-show="idShow=='5'">
							<div class="rightCon1">
								<p v-html="introJson.purchase.p1"></p>
								<ul class="doctorul">
									<li v-for="item in introJson.purchase.ul1" v-html="item"></li>
								</ul>
								<p v-html="introJson.purchase.p2"></p>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</transition>
	</main>
</template>

<script>
	// 引入json文件
	import introJson from '../../assets/json/pancaIntroData';
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				show:false,
				activeName: '1',//其他常见问题中折叠面板
				introJson, //json内容
				idShow: '1',
				dataTree: [{
					id: '1',
					label: 'PanCA',
				}, {
					id: '2',
					label: '患者须知',
					children: [{
						id: '21',
						label: '我是否需要检测？',
					}, {
						id: '22',
						label: '检测是如何进行的？',
					}]
				}, {
					id: '3',
					label: '医生须知',
					children: [{
						id: '31',
						label: '我们的技术',
					}, {
						id: '32',
						label: '可检测的肿瘤类型',
					}, {
						id: '33',
						label: '检测报告解读',
					}, {
						id: '34',
						label: '多中心临床验证',
					}, {
						id: '35',
						label: '参考文献',
					}]
				}, {
					id: '4',
					label: '其他常见问题',
				}, {
					id: '5',
					label: '我要订购',
				}],
				defaultProps: {
					children: 'children',
					label: 'label'
				}
			}
		},
		mounted() {
			var that=this;
			that.show=true
			setTimeout(function(){
				document.querySelector(".el-tree-node").firstChild.click();
			},100)
		},
		methods: {
			handleNodeClick(data) {
				var that = this;
				that.idShow = data.id;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.productMain {
		margin: 3% auto;

		.rightCon {
			color: #666666;
			font-size: 0.7rem;
			line-height: 1.2rem;
			.title {
				font-size: 0.9rem;
				color: #009900;
				line-height: 1.6rem;
			}
			img{
				width: 78%;
				height: auto;
			}
			ul{
				margin-left: -1.6em;
				font-family: "微软雅黑";
				li{
					margin: 0.2rem 0;
				}
			}
			p{
				margin: 0.2rem 0;
				font-family: "微软雅黑";
			}
			// 超链接
			.ahref{
				color: #009900 !important;
				font-family: "微软雅黑";
				text-decoration: none;
			}
			.ahref:hover{
				text-decoration: underline;
			}
			
			// 医生须知
			.doctorul{
				list-style-type: auto;
			}
			.doctorImg{
				width: 55%;
				height: auto;
			}
			// 病人须知
			.patient21{
				color: #009900 !important;
			}
			// 用于控制上下间隔
			.rightCon2 ,.rightCon3 ,.rightCon4{
				margin-top: 1%;
			}
		}
	}
</style>
