<template>
	<main class="myProduct">
		<Nav />
		<!-- 主要内容 -->
		<div class="productMain">
			<!-- 左侧菜单 -->
			<product-menu class="leftDetail"></product-menu>
			<!-- 右侧内容 -->
			<product-detail class="rightDetail" :detailData="detailData"></product-detail>
		</div>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	import productMenu from "./productMenu.vue";
	import productDetail from "./productDetail.vue";
	export default {
		name: 'product-kidney',
		components: {
			Nav,
			productMenu,
			productDetail
		},
		data() {
			return {
				detailData:{
					name:'Canhelp-Kidney 肾细胞癌基因检测',
					indication:['肾脏肿瘤'],
					background:'肾细胞癌（Renal Cell Carcinoma）是由肾实质泌尿小管上皮系统引起的恶性肿瘤，又称肾腺癌，占肾恶性肿瘤的80%～90%。根据2016年世界卫生组织分类，共有16种肾细胞癌亚型，肾脏肿瘤最常见的四种类型是透明细胞癌，乳头状细胞癌，嫌色细胞癌和嗜酸细胞腺瘤。这些组织学亚型在临床特征和预后方面各不相同，并且为其管理开发了不同的临床策略。',
					description:'Canhelp-Kidney产品基于肾细胞癌基因表达谱大数据，筛选出44个肾细胞癌特异性基因，采用实时定量聚合酶链反应(qRT-PCR)检测石蜡包埋肿瘤组织中基因的表达水平来进行分子分型。该产品可用于肾脏肿瘤的鉴别和诊断，包括三种最常见的恶性肿瘤：透明细胞癌、乳头状细胞癌和嫌色细胞癌；以及良性肿瘤嗜酸细胞腺瘤。研究人员对两组分别为1020例和129例的验证集进行了测试，准确率高达93.4% 和93%。',
					people:['肿瘤组织分化较差，无法明确类型的肾脏肿瘤患者']
				},
			}
		},
		mounted() {
			var that = this;
			that.show = true;
		}
	}
</script>

<style lang="scss" scoped>
	.myProduct{
		.productMain {
			padding: 2% 15% 5% !important;
			display: flex;
		}
		.leftDetail{
			width: 200px;
			flex-shrink: 0;
			margin-right: 20px;
		}
		.rightDetail{
			width: 100%;
		}
	}
	@media (max-width:1024px){
		.leftDetail{
			display:none !important;
		}
	}
</style>