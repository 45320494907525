<template>
	<div class="myCustomerService">
		<!-- 和我们在线交谈！ -->
		<div v-if="collapse">
			<!-- <div class="collapseDiv">
				<div class="imgleft"><img src="../assets/customerService/message.png" /></div>
				<div>和我们在线交谈！</div>
				<div class="imgright"><img src="../assets/customerService/top.png" /></div>
			</div> -->
			<div class="collapseDiv2">
				<!-- 默认 -->
				<div class="defaultIcon" v-if="collapseDefault" @mouseenter="collapseDefault=false">
					<img src="../assets/customerService/messageIcon.png" />
				</div>
				<!-- hover -->
				<div class="hoverIcon" v-else @click="expandBox"
					:style="{background:(collapseClick?'#0AA395':'#029688')}" @mouseenter="mouseHover=true" @mouseleave="hoverIconLeave">
					<img src="../assets/customerService/messageIcon.png" />
					<p>联系在线客服</p>
				</div>
			</div>
		</div>
		<main class="customerServiceBox" v-else>
			<div class="topMessage">
				<div>
					<!-- <i class="el-icon-info"></i> -->
				</div>
				<div>客服小帮</div>
				<div class="closeBtn" @click="serviceClose"><i class="el-icon-circle-close"></i></div>
			</div>
			<!-- 加载历史信息 显示在弹框中 -->
			<div class="chat-history" id="chatHistoryBox" v-on:scroll="scrollData">
				<!-- 客服信息 -->
				<div class="customerServiceDiv" v-for="(item,index) in dataList"
					:class="[item.direction?'serviceDiv':'customerDiv']">
					<div class="customerServiceImg" v-if="item.direction">
						<img src="../assets/customerService/service.png" />
					</div>
					<div class="media-body">
						<p class="p1">
							<span v-if="item.direction">{{(item.from && item.from.name) ? item.from.name:'客服小帮'}}<span
									class="timeFormat">&nbsp;&nbsp;
									{{$filters.dateTimeFormat(item.time)}}</span></span>
							<span v-else><span class="timeFormat">{{$filters.dateTimeFormat(item.time)}}
									&nbsp;&nbsp;</span>我</span>
						</p>
						<div class="pDiv">
							<div class="p2" v-html="item.message"></div>
						</div>
					</div>
					<div class="customerServiceImg" v-if="!item.direction">
						<img src="../assets/customerService/customer.png" />
					</div>
				</div>
			</div>
			<div class="chat-form">
				<el-input id="msgInput" type="textarea" resize="none" maxlength="200" placeholder="请输入您想咨询的问题" v-model="inputValue"
					@input="inputValueInput" @keyup.enter.native="sendData"></el-input>
				<el-button size="small" @click="sendData">发送</el-button>
			</div>
			<!-- <el-button size="small" @click="serviceClose">断开连接</el-button> -->
		</main>
	</div>
</template>

<script>
	import SocketService from '../assets/js/websocket'
	export default {
		name: "customerService",
		data() {
			return {
				defaultMsg: "", // 默认消息 初次进入无guid的时候显示
				message: "", //客服回复的内容
				inputValue: "", //用户输入的内容
				socketServe: null,
				// 存放数据（所有  历史 问候语句）
				dataList: [],
				// historyList: [],
				serviceInitList: [],
				collapse: true, //折叠展开
				collapseDefault: false, //折叠情况下的样式
				collapseClick: false,
				messageObj: {},
				scrollInit: false, //滚动事件
				guid: "", //当前guid
				hasMore: false, //是否有更多历史数据
				time: "", //用于加载历史数据的时间戳
				mouseHover:false,// 鼠标hover事件
			}
		},
		mounted() {
			var that = this;
			// 鼠标滚动事件 初始界面时：折叠情况下样式
			window.addEventListener('scroll', that.handleScroll);
		},
		methods: {
			handleScroll() {
				var that = this;
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 0 && (!that.scrollInit) && (!that.mouseHover)) {
					that.scrollInit = true;
					that.collapseDefault = true;
				}
			},
			scrollData(e) {
				var that = this;
				if ((e.target.scrollTop <= 0) && that.hasMore) {
					var ts = that.time;
					that.$axios.post("/api/message/customer/history/" + ts + "/10", {}, {
							headers: {
								"GUID": that.guid
							}
						})
						.then(function(res) {
							var historyList = res.data.data.data;
							that.hasMore = res.data.data.hasMore;
							that.time = historyList[0].time;
							that.dataList = historyList.concat(that.dataList);
							// 若还有数据,就默认往下滚动20px,这样用户向上滚动加载
							if(that.hasMore){
								e.target.scrollTop=20;
							}
						}).catch(function(error) {
							console.log(error)
						})
				}
			},
			// 初始化 连接
			initData() {
				var that = this;
				that.socketServe = SocketService.Instance;
				// SocketService.Instance.connect(that);
				that.socketServe.connect(that);
				// 加载客服问候语句
				var nowTime = new Date().getTime();
				that.serviceInitList = [{
					time: nowTime,
					message: "您好，我是您的<span style='color: #009688;font-weight:bolder;'>专属服务顾问</span>，很高兴为您服务。请问有什么可以帮您？",
					direction: 1,
				}, {
					time: nowTime,
					message: "为了提供更好的服务，请您留下<span style='color: #009688;font-weight:bolder;'>联系方式</span>，稍后会有专人致电为您解答。",
					direction: 1,
				}]
				that.dataList = JSON.parse(JSON.stringify(that.serviceInitList));
			},
			// 展开
			expandBox() {
				var that = this;
				that.collapseClick = true;
				setTimeout(function() {
					that.collapse = false;
					that.initData();
					that.$nextTick(() => {
						document.getElementById('msgInput').focus();
					})
				}, 100)
			},
			hoverIconLeave() {
				var that = this;
				that.collapseClick = false;
				setTimeout(function() {
					that.collapseDefault = true;
					that.mouseHover=false;
				}, 100)
			},
			// 断开连接
			serviceClose() {
				var that = this;
				that.socketServe.ws.close();
				that.collapseDefault = true;
				that.collapse = true;
				that.collapseClick = false;
			},
			// 不允许输入为空
			inputValueInput() {
				var that = this;
				that.inputValue = that.inputValue.replace(/\s+/g, "");
			},
			// 点击发送 输入框内容
			sendData() {
				var that = this;
				if (that.inputValue && (that.inputValue.trim().length > 0)) {
					var obj = {
						type: 101,
						message: that.inputValue
					}
					that.socketServe.send(that, JSON.stringify(obj));
					setTimeout(function() {
						let ele = document.getElementById('chatHistoryBox');
						ele.scrollTop = ele.scrollHeight;
					}, 10)
				} else {
					console.log("输入内容为空");
				}

			},
			// 处理返回的信息
			handleData(msg) {
				var that = this;
				that.messageObj = JSON.parse(msg.data)
				var obj = {
					time: new Date().getTime(),
					message: that.messageObj.message,
					direction: 1
				};
				that.dataList.push(obj);
				setTimeout(function() {
					let ele = document.getElementById('chatHistoryBox');
					ele.scrollTop = ele.scrollHeight;
				}, 10)
			},
			// 加载历史信息
			getHistoryList(guid) {
				var that = this;
				that.guid = guid;
				var ts = new Date().getTime();
				that.$axios.post("/api/message/customer/history/" + ts + "/10", {}, {
						headers: {
							"GUID": guid
						}
					})
					.then(function(res) {
						if (res.data.data) {
							var historyList = res.data.data.data;
							that.hasMore = res.data.data.hasMore;
							that.time =(historyList[0] && historyList[0].time)? historyList[0].time : "";
							that.dataList = historyList.concat(that.serviceInitList);
							setTimeout(function() {
								let ele = document.getElementById('chatHistoryBox');
								ele.scrollTop = ele.scrollHeight;
							}, 10)
						}
					}).catch(function(error) {
						console.log(error)
					})
			},

		},
	}
</script>

<style lang="scss" scoped>
	.myCustomerService {
		width: 560px;

		// 展开情况下
		.customerServiceBox {
			background: #F2F4F5;
			border-radius: 16px 16px 16px 16px;
			// margin: 0 auto 20px;
			width: 100%;
			box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);

			// 顶部信息
			.topMessage {
				border-radius: 16px 16px 0px 0px;
				height: 56px;
				font-size: 16px;
				background: #009688;
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #FFFFFF;
				padding: 0px 24px;

				i {
					font-size: 24px;
				}

				.closeBtn {
					cursor: pointer;
				}
			}

			// 信息框
			.chat-history {
				padding: 10px 24px;
				box-sizing: border-box;
				overflow-y: auto;
				height: 400px !important;

				.customerServiceImg {
					img {
						width: 48px;
						height: 48px;
					}
				}

				// .customerServiceDiv:not(:first-child) {
				// 	border-top: 1px solid #dcdfe6;
				// }

				.serviceDiv {
					display: flex;
					justify-content: start;

					.customerServiceImg {
						margin-right: 10px;
					}

					.media-body {
						p {
							text-align: left;
						}

						.pDiv {
							display: flex;
							align-items: center;
							justify-content: start;
						}
					}
				}

				.customerDiv {
					display: flex;
					justify-content: end;

					.customerServiceImg {
						margin-left: 10px;
					}

					.media-body {
						p {
							text-align: right;
						}

						.pDiv {
							display: flex;
							align-items: center;
							justify-content: end;
						}
					}
				}

				.serviceDiv,
				.customerDiv {
					width: 100%;
					padding: 10px 0px;
					box-sizing: border-box;

					.media-body {
						margin: 0 5px;
						max-width: 80%;

						p {
							color: #000000;
						}

						.p1 {
							margin: 0;
							height: 22px;
							font-size: 16px;
							font-weight: 600;
							color: #222834;
							line-height: 19px;

							.timeFormat {
								height: 17px;
								font-size: 12px;
								font-weight: 400;
								color: #808080;
								line-height: 14px;
							}
						}

						.p2 {
							margin-top: 4px;
							background: #FFFFFF;
							padding: 6px;
							border-radius: 6px 6px 6px 6px;
							font-size: 12px;
							font-weight: 400;
							color: #222834;
							line-height: 18px;
							word-break: break-all;
						}

						.p3 {
							margin: 0;
							font-size: 11px;
						}
					}
				}
			}

			.chat-form {
				height: 160px;
				box-sizing: border-box;
				width: 100%;
				text-align: right;
				background: #FFFFFF;
				position: relative;

				.el-textarea {
					height: 100% !important;
				}

				::v-deep .el-textarea__inner {
					height: 100% !important;
					padding: 20px;
					border: none;
				}

				.el-textarea__inner,
				::v-deep .el-textarea__inner::placeholder {
					text-align: left;
					font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
				}

				.el-button {
					margin-top: 10px;
					color: #FFFFFF;
					background: #009688;
					border-color: #009688;
					position: absolute;
					right: 24px;
					bottom: 24px;
				}

				.el-button:hover {
					background: #0BA799;
					transition: background 0.3s ease;
				}
			}

		}

		// 折叠情况
		.collapseDiv {
			float: right;
			cursor: pointer;
			width: 240px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #009688;
			color: #FFFFFF;
			height: 40px;
			font-size: 16px;

			.imgleft,
			.imgright {
				height: 100%;
				display: flex;
				width: 50px;
				align-items: center;
				justify-content: center;
			}

			.imgleft {
				background: #05756a;

				img {
					width: 25px;
					height: 25px;
				}
			}

			.imgright {
				img {
					width: 30px;
					height: 25px;
				}
			}
		}

		// 折叠情况2
		.collapseDiv2 {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: end;
			color: #FFFFFF;
			font-size: 16px;

			.defaultIcon {
				width: 64px;
				height: 64px;
				background: #029688;
				box-shadow: 0px 4px 20px 0px rgba(4, 114, 191, 0.2);
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 32px;
					height: 32px;
				}
			}

			.hoverIcon {
				width: 200px;
				height: 64px;
				box-shadow: 0px 4px 20px 0px rgba(4, 114, 191, 0.2);
				border-radius: 131px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 24px;
				box-sizing: border-box;
				transition: width 0.5s ease;

				img {
					width: 32px;
					height: 32px;
				}

				p {
					font-size: 16px;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 19px;
					white-space: nowrap;
					overflow: hidden;
				}
			}
		}
	}
</style>