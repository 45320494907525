<template>
	<main class="myProduct">
		<Nav />
		<!-- 主要内容 -->
		<div class="productMain">
			<!-- 左侧菜单 -->
			<product-menu class="leftDetail"></product-menu>
			<!-- 右侧内容 -->
			<product-detail class="rightDetail" :detailData="detailData"></product-detail>
		</div>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	import productMenu from "./productMenu.vue";
	import productDetail from "./productDetail.vue";
	export default {
		name: 'product-ubca',
		components: {
			Nav,
			productMenu,
			productDetail
		},
		data() {
			return {
				detailData:{
					name:'Canhelp-UriBLAD尿路上皮癌基因检测',
					indication:['尿路上皮癌','膀胱癌'],
					background:'尿路上皮癌（Urothelial Carcinoma，UC）是泌尿系统最常见的恶性肿瘤，在我国每年约有8万新发病例，且发病率呈逐年快速上升趋势。尿路上皮癌具有易复发的特点，及时的术后复发监测和早期诊断可以显著改善尿路上皮癌患者的生存时间和质量。目前临床上尿路上皮癌诊断和筛查的主要手段是内镜检查和尿细胞学检查，膀胱镜和输尿管镜是诊断尿路上皮癌的金标准，但是具有侵入性，给病人带来强烈的不适感，依从性差，且对于微小病变和原位癌灵敏度低，还可能造成感染和创伤；尿脱落细胞学检查灵敏度较差，结果依赖病理医生的主观判断，容易产生偏差。因此，临床亟需开发精准、无创的新型检测手段，以减少内镜及其他不必要的侵入性检查，摆脱细胞学检查主观判断的干扰，为实现尿路上皮癌的早期发现，辅助诊断和复发监测提供有力支持。',
					description:'Canhelp-UriBLAD (优睿宁）是国内首款基于尿液的尿路上皮癌基因表达谱分子诊断产品，采用实时荧光定量PCR技术，通过检测尿液中8个尿路上皮癌特征基因的表达水平，实现尿路上皮癌的辅助诊断和复发监测。目前，该产品已经获得了欧盟CE认证，标志着其产品质量和安全性得到了国际认可。',
					advantage:[{
						img:require('../../assets/images/productDetail/ubca/icon-1.png'),
						title:'灵敏精准',
						text:'检测8个尿路上皮癌特征基因的表达水平，灵敏度92.3%，特异度94.1%。'
					},{
						img:require('../../assets/images/productDetail/ubca/icon-2.png'),
						title:'无创灵活',
						text:'仅需尿液样本，非入侵性检测，患者依从度高。'
					},{
						img:require('../../assets/images/productDetail/ubca/icon-3.png'),
						title:'连续动态',
						text:'采样方便，可实现尿路上皮癌患者术后连续、动态监测。'
					},{
						img:require('../../assets/images/productDetail/ubca/icon-4.png'),
						title:'客观快速',
						text:'软件自动数据分析，结果不受主观判断影响；荧光定量PCR检测，3个工作日出具报告。'
					}],
					people:['肉眼或显微镜下血尿患者','尿频、排尿困难患者','尿急、尿痛患者','尿路上皮癌患者的术后定期复查人群']
				},
			}
		},
		mounted() {
			var that = this;
			that.show = true;
		}
	}
</script>

<style lang="scss" scoped>
	.myProduct{
		.productMain {
			padding: 2% 15% 5% !important;
			display: flex;
		}
		.leftDetail{
			width: 200px;
			flex-shrink: 0;
			margin-right: 20px;
		}
		.rightDetail{
			width: 100%;
		}
	}
	@media (max-width:1024px){
		.leftDetail{
			display:none !important;
		}
	}
</style>