<template>
	<main class="myNews">
		<Nav />
		<transition name="slide-top">
			<div v-if="show" class="companyNews">
				<h1>{{$t('news.industry.h1')}}</h1>
				<p class="techP">{{$t('news.canhelp.p1')}}<span>{{$t('news.canhelp.p2')}}</span><span>{{$t('news.canhelp.p3')}}</span></p>
				<div class="companyNewsDiv">
					<el-row type="flex" :gutter="20" v-for="(item,index) in $i18n.locale=='zh'?zhNews.data:enNews.data" :key="item.id" class="newsRow">
						<router-link :to="{path:'/newsinfo', query:{id:item.id,key:2}}">
							<el-col :xs="4" :sm="4" :md="4">
								<!-- <img v-show="item.skin" class="newsImg" :src="`${imgUrl}/`+`${item.skin}`"> -->
								<!-- <div class="imgDIv">
									<img v-show="item.skin" class="newsImg" :src="`${item.skin}`">
								</div> -->
								<div class="imgBg" :style="{ backgroundImage: `url(${item.skin})` }" >
								</div>
							</el-col>
							<el-col :xs="20" :sm="20" :md="20" class="rightText">
								<el-col :xs="6" :sm="6" :md="6">
									<span class="newsSpan">{{item.date}}</span>
								</el-col>
								<el-col :xs="18" :sm="18" :md="18">
									<div class="newsText">
										<p>{{item.title}}</p>
										<p>{{item.summary}}</p>
									</div>
								</el-col>
							</el-col>
						</router-link>
					</el-row>
					<el-pagination layout="prev, pager, next" :total="$i18n.locale=='zh'?zhtotal:entotal" :current-page.sync="currentPage"
						:page-size="pageSize" @current-change="handleCurrentChange"></el-pagination>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				// 分页
				currentPage: 1,
				total: 0, // 总页数
				pageSize: 10, //一页多少条数据
				newsData:[],
				show:false,
				zhNews:[],
				zhtotal:0,
				enNews:[],
				entotal:0,
			}
		},
		watch: {
		    '$i18n.locale'(val, newval) {
			    var that=this;
		        if (val != newval) {
			        that.handleCurrentChange(0);
		       	}
		    }
		},
		mounted() {
			var that=this;
			// 获取url中num的值
			if(that.$route.query && that.$route.query.num){
				that.currentPage=parseInt(that.$route.query.num);
				that.getNewsList(that.currentPage);
			}else{
				that.currentPage=1;
				that.getNewsList(0);
			}
			that.show=true;
		},
		methods:{
			handleCurrentChange(page){
				var that=this;
				that.$router.push(`${that.$route.path}?num=${page}`);
				that.getNewsList(page);
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				document.documentElement.scrollTop=0;
			},
			getNewsList(page) {
				var that = this;
				const pageNum=parseInt(page)>0 ? parseInt(page)-1:0;
				that.$axios
					.get("/api/news/list/type/2/"+pageNum+"/"+that.pageSize)
					.then(function(res) {
						that.zhNews= res.data.data;
						that.zhtotal=res.data.data.totalCount;
						that.currentPage=pageNum +1;
					})
					.catch(function(error) {});
				// that.$axios
				// 	.get("/api/engNews/list/type/2/"+pageNum+"/"+that.pageSize)
				// 	.then(function(res) {
				// 		that.enNews= res.data.data;
				// 		that.entotal=res.data.data.totalCount;
				// 		that.currentPage=pageNum +1;
				// 	})
				// 	.catch(function(error) {});
			},
		}
	}
</script>

<style lang="scss" scoped>
	@media only screen and (max-width: 991px){
		.newsText{
			p:last-child{
				display: none;
			}
		}
	}
</style>
