<template>
	<main class="myProduct">
		<Nav />
		<!-- 主要内容 -->
		<transition name="slide-top">
			<div v-if="show" class="casesMain">
				<h1>案例分析</h1>
				<p class="casesP">致力研发<span>新一代</span><span>肿瘤分子诊断试剂</span></p>
				<div class="casesDiv">
					<el-row :gutter="40">
						<el-col :xs="24" :sm="12" :md="8">
							<div class="casesCol">
								<img src="../../assets/images/2_07.jpg">
								<div class="casesDiv1Item">
									<h3>三阴性乳腺癌案例</h3>
									<p>三阴性乳腺癌是指肿瘤组织经免疫组织化学检查结果为雌激素受体（ER）、孕激素受体（PR）...</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<div class="casesCol">
								<img src="../../assets/images/2_08.jpg">
								<div class="casesDiv1Item">
									<h3>转移性生殖细胞瘤案例</h3>
									<p>三阴性乳腺癌是指肿瘤组织经免疫组织化学检查结果为雌激素受体（ER）、孕激素受体（PR）...</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<div class="casesCol">
								<img src="../../assets/images/2_09.jpg">
								<div class="casesDiv1Item">
									<h3>双原发肿瘤病例</h3>
									<p>当一位有既往肿瘤病史的患者在多年之后又一次被确诊患上肿瘤的时候，他的内心是崩溃的...</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<div class="casesCol">
								<img src="../../assets/images/2_07.jpg">
								<div class="casesDiv1Item">
									<h3>三阴性乳腺癌案例</h3>
									<p>三阴性乳腺癌是指肿瘤组织经免疫组织化学检查结果为雌激素受体（ER）、孕激素受体（PR）...</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<div class="casesCol">
								<img src="../../assets/images/2_08.jpg">
								<div class="casesDiv1Item">
									<h3>转移性生殖细胞瘤案例</h3>
									<p>三阴性乳腺癌是指肿瘤组织经免疫组织化学检查结果为雌激素受体（ER）、孕激素受体（PR）...</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8">
							<div class="casesCol">
								<img src="../../assets/images/2_09.jpg">
								<div class="casesDiv1Item">
									<h3>双原发肿瘤病例</h3>
									<p>当一位有既往肿瘤病史的患者在多年之后又一次被确诊患上肿瘤的时候，他的内心是崩溃的...</p>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	import Nav from "../nav.vue";	
	export default {
		components:{
			Nav
		},
		data() {
			return {
				activeName: 'first',
				show:false
			}
		},
		mounted() {
			var that=this;
			that.show=true
		}
	}
</script>

<style lang="scss" scoped>
.myProduct{
	background: #F5F5F5;
}
</style>
