<template>
	<main class="myTech">
		<Nav />
		<!-- 主要内容 -->
		<transition name="slide-top">
			<div v-if="show" class="techMain">
				<h1>技术服务</h1>
				<p class="techP">致力研发<span>新一代</span><span>肿瘤分子诊断试剂</span></p>
				<div class="serviceDiv">
					<div class="serviceDiv1">
						<img src="../../assets/images/3_19.jpg">
						<p>形态病理学服务</p>
					</div>
					<div class="serviceDiv2">
						<el-collapse class="myCollapse" v-model="activeName1" accordion>
							<el-collapse-item title="一、HE染色、特殊染色" name="1">
								<div class="myCollapseBox">
									<p>主要包括从实验标本取材，蜡块制作、切片染色（HE及各项特殊染色） 至免疫组化服务及组织芯片构建等各个层面。公司拥有多台大型自动化病理制片设备，并由专业的病理技术员操作，
										可满足客户在各个环节中的精度要求（微小标本、特殊包埋面等），充分保证制片质量。</p>
									<el-row :gutter="40">
										<el-col :xs="12" :sm="8" :md="8">
											<img src="../../assets/images/service/he_1.jpg">
										</el-col>
										<el-col :xs="12" :sm="8" :md="8">
											<img src="../../assets/images/service/he_2.jpg">
										</el-col>
										<el-col :xs="12" :sm="8" :md="8">
											<img src="../../assets/images/service/he_3.jpg">
										</el-col>
									</el-row>
								</div>
							</el-collapse-item>
							<el-collapse-item title="二、组织芯片(tissue chip)" name="2">
								<div class="myCollapseBox">
									<p>组织芯片是生物芯片技术的一个重要分支，是将许多不同个体组织标本以规则阵列方式排布于同一载玻片上，
										进行同一指标的高通量原位组织学研究。我公司采用稳定性高，黏附性佳的高品质预制备受体蜡块，
										点阵排列整齐美观，并可为您量身定制人类样本组织芯片、动物样本组织芯片、植物样本组织芯片等特殊样本组织芯片。
										由专业病理诊断医师进行阅片，确认原有诊断，统一诊断标准，并对切片及供体蜡块双标记，提高采样精准。</p>
								</div>
							</el-collapse-item>
							<el-collapse-item title="三、病理分析服务" name="3">
								<div class="myCollapseBox">
									<p>
										公司以其天然优势汇聚了多位国内外病理学科高级专家，技术人员技能娴熟，诊断人员经验丰富，
										能够为科研研究提供专业、快速、精准的病理分析服务。主要包括临床样本病理诊断结果复核、免疫组化结果判读、
										肿瘤学及毒理学动物模型病理表型分析以及投稿前病理图片审核等，所有服务均提供包括镜下病理描述、
										数码图片及实验结果分析在内的专业病理分析报告。
									</p>
								</div>
							</el-collapse-item>
							<el-collapse-item title="四、免疫组化（Immunohistochemistry，IHC）" name="4">
								<div class="myCollapseBox">
									<el-row :gutter="40">
										<el-col :xs="24" :sm="12" :md="16">
											<p>
												是利用免疫学中抗原抗体特异性结合的原理，通过化学反应使标记抗体的显色剂（荧光素、酶、金属离子、
												同位素）显色来确定组织细胞内抗原（如多肽和蛋白质），对其进行定位、定性及定量的研究。
												免疫组化技术广泛应用于生物学检测领域，包括异常细胞的诊断，生物标志物的分布和定位研究，
												蛋白质在生物组织中不同部位差异表达的检测等。
											</p>
											<p class="paddingTop">
												我公司配备了自动化免疫组化仪，拥有多种经过预实验验证的抗体， 极大的提高了实验结果的可靠性，免去抗体订购等实验流程，缩短周期，降低投入。
											</p>
										</el-col>
										<el-col :xs="24" :sm="12" :md="8">
											<img src="../../assets/images/service/ihc_1.png">
										</el-col>
									</el-row>
									<el-row :gutter="40">
										<el-col :xs="24" :sm="12" :md="16">
											<ul class="paddingTop">
												<li>
													组织切片的制备：<span>客户可以提供新鲜的组织，由我们负责进行组织固定及石蜡包埋； 也可以提供冷冻或石蜡包埋的组织或细胞样品进行分析。</span>
												</li>
												<li>抗体的选择：<span>客户可以自行提供检测所需的一抗， 也可以由我们负责从现有的经优化的抗体中筛选最合适的抗体。检测所需的二抗由我们提供。</span></li>
												<li>染色：<span>免疫组化的染色过程在载玻片上进行。即使靶抗原的表达水平极低， 我们的专利技术也可以将染色的信号最大化。</span></li>
												<li>报告：<span>可发表的形式、详细的病理报告，附带高清照片。</span></li>
												<li> 分析：<span>客户可以要求与我们资深的病理学家讨论相关报告。</span></li>
											</ul>
										</el-col>
										<el-col :xs="24" :sm="12" :md="8">
											<img src="../../assets/images/service/ihc_2.png">
										</el-col>
									</el-row>
								</div>
							</el-collapse-item>
						    <el-collapse-item title="五、原位杂交（In situ hybridization，ISH）" name="5">
						    	<div class="myCollapseBox">
						    		<p>
			                    是利用核酸分子单链之间有互补的碱基序列，将有放射性或非放射性的外源核酸（即探针）与组织、
			                    细胞或染色体上待测DNA或RNA互补配对，结合成专一的核酸杂交分子，
			                    经一定的检测手段将待测核酸在组织、细胞或染色体上的位置显示出来。
			                    </p>
								<p class="ishPadding">
								  <b>样品制备：</b><br>
								  我们为多个应用建立了专有的实验方案以增强灵敏度和信号，包括：<br>
								  细胞染色体（间期，中期和培养的细胞）；<br>
								  组织染色体（福尔马林固定的、石蜡包埋组织切片或细胞玻片）；<br>
								  RNA（细胞内RNA定位，RNA加工，定量）。<br>
								  探头设计、标记及纯化：利用专有技术，设计了具有极高灵敏度和特异性探针，并提供多种标记以供选择。<br>
								  杂交：定制优化杂交条件。<br>
								  报告：可发表的形式的报告及高清照片。<br>
								  分析：在做每一个项目之前，我们的专业技术人员将与您讨论实验设计的细节，帮助您以最具效益和最快捷的方式来得到最好的结果。<br>
								  材料的制备: 采购，包埋和检测组织切片。<br>
								  探头设计、标记及纯化: DNA和RNA探针的合成（同位素和非同位素）。<br>
								  杂交：杂交条件优化：有义和反义探针与特定组织的杂交。<br>
								  ISH染色: 使用专用的试剂和方法。<br>
								  报告: 图像及染色玻片。<br>
								  质量控制: 由于该技术的复杂性，每个项目都包括合适的标记和杂交条件控制。 特别是，使用看家基因的对照探针来确定由客户提供的组织中的mRNA的质量和数量。通常，我们使用对照组织来验证结果。<br>
								  分析: 客户可以要求与我们资深的专业技术人员讨论相关报告。<br>
								</p>
								<p>此外，公司还能提供多种探针产品及探针合成服务。</p>
								<el-row :gutter="40">
									<el-col :xs="12" :sm="8" :md="8">
										<img src="../../assets/images/service/ish_1.jpg">
									</el-col>
									<el-col :xs="12" :sm="8" :md="8">
										<img src="../../assets/images/service/ish_2.jpg">
									</el-col>
									<el-col :xs="12" :sm="8" :md="8">
										<img src="../../assets/images/service/ish_3.png">
									</el-col>
								</el-row>
						    	</div>
						    </el-collapse-item>
						</el-collapse>
					</div>
					<div class="serviceDiv3">
						<img src="../../assets/images/3_19.jpg">
						<p>细胞检测服务</p>
					</div>
					<div class="serviceDiv4">
						<el-collapse class="myCollapse" v-model="activeName2" accordion>
							<el-collapse-item title="一、细胞周期检测（cell cycle）" name="1">
								<div class="myCollapseBox">
									<el-row :gutter="40">
										<el-col :xs="24" :sm="12" :md="16">
											<p>
												细胞周期是指细胞从一次分裂完成开始到下一次分裂结束所经历的全过程，分为间期与分裂期两个阶段。 在这一过程中，细胞的遗传物质复制并均等地分配给两个子细胞。生命即是通过细胞遗传物质从一代向下一代传递的连续过程， 因此是一个不断更新、不断从头开始的过程。细胞的生命开始于产生它的母细胞的分裂，结束于它的子细胞的形成，或是细胞的自身死亡。
			
											</p>
											<p class="paddingTop">
												通过对细胞周期的测定，可以作为生物相容性评价指标，或用于研究细胞凋亡，也可作为选择细胞的依据。 目前最常用的细胞周期检测方法，即是PI染色检测。
											</p>
											<p class="paddingTop">
												细胞周期各个时期的细胞内DNA含量不同， 利用荧光染料碘化丙啶(PI）能够和细胞内DNA结合的特性，根据不同DNA量结合的荧光染料量不同， 因而流式细胞仪检测的荧光强度也不一样，可用来反应细胞周期的各个期的状况，即细胞增殖状况。
										
											
											</p>
											<p class="paddingTop">
												收集细胞 ----> 细胞固定 ----> 细胞染色 ----> 流式分析
											</p>
										</el-col>
										<el-col :xs="24" :sm="12" :md="8">
											<img src="../../assets/images/service/cycle_1.png">
										</el-col>
										<el-col :xs="24" :sm="12" :md="8">
											<img src="../../assets/images/service/cycle_2.png">
										</el-col>
									</el-row>
								</div>
							</el-collapse-item>
							<el-collapse-item title="二、细胞凋亡检测（Apoptosis）" name="2">
								<div class="myCollapseBox">
									<el-row :gutter="40">
										<el-col :xs="24" :sm="12" :md="16">
											<p>
												细胞凋亡是指为维持内环境稳定，由基因控制的细胞自主的有序的死亡。作为细胞的一种基本生物学现象， 细胞凋亡是机体为更好地适应生存环境，而主动争取的一种死亡过程，它涉及一系列基因的激活、表达以及调控等的作用， 一般过程包括4个阶段，即：诱导启动、细胞内调控、实施和凋亡细胞的吞噬搬运。细胞凋亡不仅是一种特殊的细胞死亡类型， 而且具有重要的生物学意义及复杂的分子生物学机制。
									
											</p>
											<p class="paddingTop">
												细胞凋亡对胚胎发育及形态发生、组织内正常细胞群的稳定、机体的防御和免疫反应、疾病或中毒时引起的细胞损伤、 老化、肿瘤的发生进展起着重要作用，并具有潜在的治疗意义，一直是生物医学研究的热点。
											</p>
											<p class="paddingTop">
												除了传统的凋亡细胞形态学检测，细胞凋亡的检测方法多种多样，且根据凋亡早期和晚期的样品，选择不同的检测手段。
											</p>
										</el-col>
										<el-col :xs="24" :sm="12" :md="8">
											<img src="../../assets/images/service/apoptosis.png">
										</el-col>
									</el-row>
								</div>
							</el-collapse-item>
							<el-collapse-item title="三、细胞流式检测" name="3">
								<div class="myCollapseBox">
									<el-row :gutter="40">
										<el-col :xs="24" :sm="12" :md="16">
											<p>
												流式细胞术是一种在液体系统中，快速测定单个细胞或细胞器的生物、物理和化学特性， 并根据这些特性将所需要的细胞或细胞器进行定量分析和分选的技术。它是一种可以快速、 准确的检测细胞或者生物学颗粒的多种特性的技术，用来了解细胞群的均值和分布情况， 并还可以分选感兴趣的细胞、对该细胞做进一步的培养研究。
									
											</p>
											<p class="paddingTop">
												<b>技术流程</b>
												<ul>
													<li>
														 样品制备：
														<span>
															对于不同组织来源的实体组织和实体瘤标本，应该根据各自特点选择不同的分散细胞方法， 以期达到单细胞产量高、损伤小的目的。如酶消化法、机械法等；
														</span>
													</li>
													<li>
														荧光标记：
														<span>
															荧光素偶联抗体标记、荧光染料/荧光化合物标记、荧光蛋白；
														</span>
													</li>
													<li>
														流式分析：
														<span>
															根据客户需要设置空白对照、阳性对照、同型对照进行实验；
														</span>
													</li>
													<li>
														结果分析：
														<span>
															根据客户需要提供散点图、直方图、等高线图等。
														</span>
													</li>
												</ul>
											</p>
										</el-col>
										<el-col :xs="24" :sm="12" :md="8">
											<img src="../../assets/images/service/cfc_1.png">
										</el-col>
									</el-row>
									<el-row :gutter="40">
										<el-col :xs="24" :sm="16" :md="16">
											<img src="../../assets/images/service/cfc_3.png">
										</el-col>
										<el-col :xs="24" :sm="12" :md="8">
											<img src="../../assets/images/service/cfc_2.png">
										</el-col>
									</el-row>
								</div>
							</el-collapse-item>
							<el-collapse-item title="四、细胞自噬检测（Autophagy）" name="4">
								<div class="myCollapseBox">
									<el-row :gutter="40">
										<el-col :xs="24" :sm="24" :md="24">
											<p>
												自噬，或称自体吞噬，是一个涉及到细胞自身结构通过溶酶体机制而被分解的过程，该过程是一个受到紧密调控的步骤， 帮助细胞产物在合成、降解以及接下来的循环中保持一个平衡状态。 “自噬”一词是由比利时化学家克里斯汀•德•迪夫在1963年创造的。90年代酵母的研究人员通过识别自噬相关基因， 从而推动了自噬研究的开展。日本科学家大隅良典因对“细胞自噬机制的发现”，获得了2016年度的诺贝尔生理学或医学奖。 在生物体内发育、分化、环境胁迫和肿瘤发生中具有重要的意义。
											</p>
											<p class="paddingTop">
												目前已有研究证明多种因素能够诱导细胞发生自噬，如饥饿、生长因子缺乏、微生物感染、细胞器损伤、 蛋白质折叠错误或聚集、DNA损伤、放疗、化疗等。但是关于自噬信号的传递，现有的研究只明确了几种信号通路， 包括抑制类和激活类，但具体的机制尚在研究中。
											</p>
											<p class="paddingTop">
												细胞接受自噬诱导信号后，在胞浆的某处形成一个扁平的类似“脂质体”样的膜结构，称为Phagophore。 随着Phagophore不断延伸，将胞浆中的细胞器等成分，全部包裹住成为密闭的结构，称为“自噬体（autophagosome）”。 自噬体形成后，可与溶酶体融合，自噬体中的内容物随即被降解，产物（氨基酸、脂肪酸等）被输送到胞浆中， 供细胞重新利用，而残渣或被排出细胞外或滞留在胞浆中。
											</p>
											<p class="paddingTop">
												我们能够提供的检测策略包括以下几种：
											</p>
											<ul>
												<li>
													<span>
														投射电镜下观察自噬体的形成
													</span>
												</li>
												<li>
													<span>
														荧光显微镜下观察GFP-LC3融合蛋白以示踪自噬形成
													</span>
												</li>
												<li>
													<span>
														Western Blot方法检测LC3-ⅣⅠ比值的变化以评价自噬形成
													</span>
												</li>
												<li>
													<span>
														MDC（monodansylcadaverine，单丹磺酰戊二胺）染色
													</span>
												</li>
											</ul>
										</el-col>
										<el-col :xs="24" :sm="24" :md="24" class="paddingTop">
											<el-col :xs="12" :sm="12" :md="12">
												<img src="../../assets/images/service/autophagy_1.png">
											</el-col>
											<el-col :xs="12" :sm="12" :md="12">
												<img src="../../assets/images/service/autophagy_2.png">
											</el-col>
										</el-col>
									</el-row>
								</div>
							</el-collapse-item>
						    <el-collapse-item title="五、细胞免疫荧光" name="5">
						    	<div class="myCollapseBox">
						    		<p>
					           细胞免疫荧光是根据抗原抗体反应的原理，先将已知的抗原或抗体标记上荧光素制成荧光标记物， 再用这种荧光抗体（或抗原）作为分子探针检查细胞或组织内的相应抗原（或抗体）。 在细胞或组织中形成的抗原抗体复合物上含有荧光素，利用荧光显微镜观察标本， 荧光素受激发光的照射而发出明亮的荧光（黄绿色或桔红色），可以看见荧光所在的细胞或组织， 从而确定抗原或抗体的性质、定位，以及利用定量技术测定含量。
					            </p>
								<el-row :gutter="40">
									<el-col :xs="24" :sm="16" :md="16">
										<img src="../../assets/images/service/imf_1.png">
										<img src="../../assets/images/service/imf_2.png">
									</el-col>
									<el-col :xs="24" :sm="8" :md="8">
										<img src="../../assets/images/service/imf_4.png">
									</el-col>
								</el-row>
						    	</div>
						    </el-collapse-item>
						</el-collapse>
					</div>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				activeName1: '0',
				activeName2: '0',
				show:false
			}
		},
		mounted() {
			var that=this;
			that.show=true
		},
	}
</script>

<style lang="scss" scoped>
</style>
