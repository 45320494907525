<template>
	<main class="myAbout">
		<Nav />
		<transition name="slide-top">
			<div v-if="show">
				<div class="cultureDiv">
					<h1>{{$t('about.culture.h1')}}</h1>
					<p class="techP">{{$t('about.culture.p1')}}<span>{{$t('about.culture.p2')}}</span><span>{{$t('about.culture.p3')}}</span></p>
					<p class="textP">{{$t('about.culture.text')}}</p>
					<div class="cultureRow">
						<el-row :gutter="40" type="flex">
							<el-col :xs="24" :sm="12" :md="6">
								<div class="colDiv">
									<img src="../../assets/images/5_30.jpg"/>
									<p class="p1">{{$t('about.culture.item1.p')}}</p>
									<hr>
									<p class="p2">{{$t('about.culture.item1.text')}}</p>
								</div>
							</el-col>
							<el-col :xs="24" :sm="12" :md="6">
								<div class="colDiv">
									<img src="../../assets/images/5_31.jpg"/>
									<p class="p1">{{$t('about.culture.item2.p')}}</p>
									<hr>
									<p class="p2">{{$t('about.culture.item2.text')}}</p>
								</div>
							</el-col>
							<el-col :xs="24" :sm="12" :md="6">
								<div class="colDiv">
									<img src="../../assets/images/5_32.jpg"/>
									<p class="p1">{{$t('about.culture.item3.p')}}</p>
									<hr>
									<p class="p2">{{$t('about.culture.item3.text')}}</p>
								</div>
							</el-col>
							<el-col :xs="24" :sm="12" :md="6">
								<div class="colDiv">
									<img src="../../assets/images/5_33.jpg"/>
									<p class="p1">{{$t('about.culture.item4.p')}}</p>
									<hr>
									<p class="p2">{{$t('about.culture.item4.text')}}</p>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="cultureStyle">
					<div class="cultureStyleDiv">
						<h1>Culture</h1>
						<hr>
						<p class="techP">{{$t('about.culture.item.p1')}}<span>{{$t('about.culture.item.p2')}}</span><span>{{$t('about.culture.item.p3')}}</span></p>
						<p class="textP">{{$t('about.culture.item.text')}}</p>
					</div>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				show:false
			}
		},
		mounted() {
			var that=this;
			that.show=true
		}
	}
</script>

<style lang="scss" scoped>
	.myAbout{
		background:#f0f3f5 !important;
	}
	// @media only screen and (max-width:768px) {
	// 	.el-col:nth-child(2n){
	// 		border-right: 1px solid rgba(255,255,255,1) !important;
	// 	}
	// 	.el-col:nth-child(25){
	// 		border-bottom: 1px solid rgba(255,255,255,1) !important;
	// 	}
	// }
	
</style>
