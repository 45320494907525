<template>
	<main class="myTech">
		<Nav />
		<!-- 主要内容 -->
		<transition name="slide-top">
			<div v-if="show" class="techMain">
				<h1>{{$t('tech.support.h1')}}</h1>
				<p class="techP">{{$t('tech.support.p1')}}<span>{{$t('tech.support.p2')}}</span><span>{{$t('tech.support.p3')}}</span></p>
				<div class="techDiv">
					<el-row class="techDiv1">
						<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
							<div class="techDiv1Item">
								<img src="../../assets/images/support/21.png">
								<div>
									<h3>{{$t('tech.support.itme1.h3')}}</h3>
									<p>{{$t('tech.support.itme1.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
							<div class="techDiv1Item">
								<img src="../../assets/images/support/22.png">
								<div>
									<h3>{{$t('tech.support.itme2.h3')}}</h3>
									<p>{{$t('tech.support.itme2.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
							<div class="techDiv1Item">
								<img src="../../assets/images/support/23.png">
								<div>
									<h3>{{$t('tech.support.itme3.h3')}}</h3>
									<p>{{$t('tech.support.itme3.p')}}</p>
								</div>
							</div>
						</el-col>
						<div class="dot-line"></div>
					</el-row>
					<el-row class="techDiv2">
						<el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
							<div class="techDiv1Item">
								<img src="../../assets/images/support/27.png">
								<div>
									<h3>{{$t('tech.support.itme7.h3')}}</h3>
									<p>{{$t('tech.support.itme7.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
							<div class="techDiv1Item">
								<img src="../../assets/images/support/26.png">
								<div>
									<h3>{{$t('tech.support.itme6.h3')}}</h3>
									<p>{{$t('tech.support.itme6.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
							<div class="techDiv1Item">
								<img src="../../assets/images/support/25.png">
								<div>
									<h3>{{$t('tech.support.itme5.h3')}}</h3>
									<p>{{$t('tech.support.itme5.p')}}</p>
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
							<div class="techDiv1Item">
								<img src="../../assets/images/support/24.png">
								<div>
									<h3>{{$t('tech.support.itme4.h3')}}</h3>
									<p>{{$t('tech.support.itme4.p')}}</p>
								</div>
							</div>
						</el-col>
						<div class="dot-line"></div>
					</el-row>
				    <div class="dot-circle"></div>
				</div>
			</div>
		</transition>
	</main>
</template>

<script>
	import Nav from "../nav.vue";	
	export default {
		components:{
			Nav
		},
		data() {
			return {
				activeName: 'first',
				show:false
			}
		},
		mounted() {
			var that=this;
			that.show=true
		}
	}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 991px){
	.techDiv{
		.dot-line{
			display: none;
			background: none;
		}
		.dot-circle{
			@extend .dot-line;
		}
		.techDiv1{
			margin: 0 !important;
			.techDiv1Item{
				display: flex;
				align-items: center;
				margin: 3% auto !important;
				img{
					width: 4.5rem !important;
					height: 4.5rem !important;
				}
				div{
					margin-left: 20px;
					text-align: left;
					p{
						width: 100% !important;
						text-align: left !important;
						margin-left: 0 !important;
					}
				}
			}
		}
		.techDiv2{
			@extend .techDiv1;
			display: flex;
			flex-direction: column-reverse;
		}
	}
}
</style>
