<template>
	<div class="productDetail">
		<div class="productName">{{detailData.name}}</div>
		<div class="indicationDiv" v-if="detailData.indication && detailData.indication.length>0">
			<p>适应症：</p>
			<div v-for="item in detailData.indication">{{item}}</div>
		</div>
		<div class="backgroundDiv" v-if="detailData.background">
			<div class="title">
				<img src="../../assets/images/productDetail/background.png">
				<div>
					<span>背景</span>
					<span>/Background</span>
				</div>
			</div>
			<div class="content">
				<p>
					{{detailData.background}}
				</p>
			</div>
		</div>
		<div class="descriptionDiv" v-if="detailData.description">
			<div class="title">
				<img src="../../assets/images/productDetail/description.png">
				<div>
					<span>产品介绍</span>
					<span>/Product description</span>
				</div>
			</div>
			<div class="content">
				<p>
					{{detailData.description}}
				</p>
			</div>
		</div>
		<div class="advantageDiv" v-if="detailData.advantage && detailData.advantage.length>0">
			<div class="title">
				<img src="../../assets/images/productDetail/advantage.png">
				<div>
					<span>产品优势</span>
					<span>/Product advantages</span>
				</div>
			</div>
			<div class="content">
				<div class="advantageItem" v-for="item in detailData.advantage">
					<img :src="item.img" />
					<div class="subTitle">{{item.title}}</div>
					<div class="subContent">{{item.text}}</div>
				</div>
			</div>
		</div>
		<div class="peopleDiv" v-if="detailData.people  && detailData.people.length>0">
			<div class="title">
				<img src="../../assets/images/productDetail/people.png">
				<div>
					<span>适用人群</span>
					<span>/For people</span>
				</div>
			</div>
			<div class="content">
				<div class="peopleItem" v-for="item in detailData.people">
					<span class="xingIcon"></span>
					<span class="text">{{item}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			detailData: Object
		},
		data() {
			return {
			}
		},
		mounted() {
			var that = this;
		}
	}
</script>

<style lang="scss" scoped>
	.productDetail {
		.productName {
			font-weight: bold;
			font-size: 1.4rem;
			color: #0472BF;
		}

		.indicationDiv {
			display: flex;
			align-items: center;
			font-weight: bold;
			margin-top: 24px;

			p {
				font-size: 0.9rem;
				color: #333333;
			}

			div {
				margin-left: 12px;
				font-size: 0.8rem;
				color: #0472BF;
				height: 32px;
				line-height: 32px;
				padding: 0 12px;
				border-radius: 32px;
				background: #F5F5F5;
			}
		}

		.backgroundDiv,
		.descriptionDiv,
		.advantageDiv,
		.peopleDiv {
			margin-top: 40px;

			.title {
				display: flex;
				align-items: center;
				font-weight: bold;
				margin-bottom: 24px;

				div {
					margin-left: 10px;
					font-size: 1rem;

					span:nth-child(1) {
						color: #333333;
					}

					span:nth-child(2) {
						color: #B3B3B3;
					}
				}
			}

			.content {
				margin-left: 34px;
				font-size: 0.8rem;
				color: #666666;

				p {
					line-height: 24px;
				}

			}
		}

		.advantageDiv {
			.content {
				display: flex;
				text-align: center;
				justify-content: space-around;
				flex-direction: row;
				align-items: stretch;

				.advantageItem {
					width: 156px;
					padding: 10px;

					.subTitle {
						font-weight: bold;
						font-size: 0.8rem;
						color: #333333;
						margin: 8px auto;
					}

					.subContent {
						font-size: 0.7rem;
						color: #666666;
						text-align: center;
						line-height: 21px;
					}
				}
			}
		}

		.peopleDiv {
			.peopleItem {
				.xingIcon {
					display: inline-block;
					width: 8px;
					height: 8px;
					background: #57DAAC;
					border-radius: 4px;
				}

				.text {
					margin-left: 8px;
					font-weight: 400;
					font-size: 0.8rem;
					color: #666666;
					line-height: 24px;
				}
			}
		}
	}
</style>