<template>
	<main class="myAbout">
		<Nav />
		<transition name="slide-top">
			<div v-if="show">
				<div class="institutionDiv">
					<h1>投资机构</h1>
					<p class="textp">公司的核心团队由国家重点学科带头人领衔，国内外生物信息学和转化医学高端人才组成，多年来致力于生物标志物的基础研究和临床应用，已在Cancer Research、Clinical Cancer<br> Research等国际著名刊物发表论文60余篇，累计影响因子高达350+，并获得多项发明专利。</p>
				</div>
				<div class="institutionInfo">
					<div class="institutionUnder">
						<img src="../../assets/images/5_28.jpg">
					</div>
					<div class="institutionTop">
						<ul>
							<li>苏州工业园区元禾原点创业投资管理有限公司</li>
							<li>苏州工业园区元禾原点创业投资管理有限公司</li>
							<li>苏州工业园区元禾原点创业投资管理有限公司</li>
							<li>苏州工业园区元禾原点创业投资管理有限公司</li>
						</ul>
					</div>
				</div>
			</div>
		</transition>	
	</main>
</template>
<script>
	import Nav from "../nav.vue";
	export default {
		components: {
			Nav
		},
		data() {
			return {
				show:false
			}
		},
		mounted(){
			var that = this;
			that.show = true;
		},
	}
</script>

<style lang="scss" scoped>
	.institutionInfo{
		margin-top: -1.5%;
		position: relative;
		margin-bottom: 5%;
		.institutionUnder{
			margin: 0 auto;
			width: 55%;
			img{
				width: 100%;
			}
		}
		.institutionTop{
			position: absolute;
			left: 10%;
			top: -11%;
			padding: 3%;
			width: 28;
			color: #FFFFFF;
			background-color: #57DAAC;
			background-image: linear-gradient(90deg, #57DAAC, #0681BF);
			ul{
				font-size: 0.7rem;
				margin-left: -2em;
				margin: 0 auto;
				li{
					height: 3rem;
					line-height: 3rem;
					border-bottom: 1px solid #FFFFFF;
				}
				li:hover{
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	}
	@media only screen and (max-width:991px) {
		.institutionInfo{
			margin-top: 1%;
			.institutionUnder{
				width: 70%;
			}
			.institutionTop{
				position: relative !important;
				left: 0;
				right: 0;
				width: 70%;
				margin: 0 auto;
				margin-top: 3%;
			}
		}
	}
	
</style>
