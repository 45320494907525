<template>
	<main class="myProduct">
		<Nav />
		<!-- 主要内容 -->
		<div class="productMain">
			<!-- 左侧菜单 -->
			<product-menu class="leftDetail"></product-menu>
			<!-- 右侧内容 -->
			<product-detail class="rightDetail" :detailData="detailData"></product-detail>
		</div>
	</main>
</template>

<script>
	import Nav from "../nav.vue";
	import productMenu from "./productMenu.vue";
	import productDetail from "./productDetail.vue";
	export default {
		name: 'product-coo',
		components: {
			Nav,
			productMenu,
			productDetail
		},
		data() {
			return {
				detailData:{
					name:'Canhelp-COO弥漫性大B细胞淋巴瘤起源分型基因检测',
					indication:['弥漫性大B细胞淋巴瘤（DLBCL）'],
					background:'弥漫性大B细胞淋巴瘤（Diffuse large B-cell lymphoma, DLBCL）是淋巴瘤中最常见的亚型。在中国，每年新增淋巴瘤患者约为10万人，其中，DLBCL约占所有淋巴瘤的33%。2016修订版WHO淋巴造血组织肿瘤分类根据全基因组表达谱的不同，将DLBCL按照细胞起源分为生发中心B细胞样亚型（GCB）、活化B细胞样亚型（ABC）和无法分类的亚型（UC）。DLBCL-COO亚型是评估患者预后的重要因素。采用标准R-CHOP治疗方案，GCB型和ABC型的5年生存率分别为80%和51%，GCB型预后显著优于ABC型。因此，国内外淋巴瘤指南（NCCN指南、CSCO指南等）均要求对DLBCL进行分子分型，临床医生可根据DLBCL不同COO亚型制定有针对性的治疗策略。',
					description:'Canhelp-COO 是一款多基因分子诊断产品，通过检测肿瘤组织中一组特定基因的表达水平，联合人工智能算法判断COO分子亚型，与金标准比较一致性可达92%。精准的COO分子分型可以为临床医生提供更准确的信息，为治疗方案的制定提供参考和依据，帮助患者最大程度的从治疗中获益。',
					advantage:[{
						img:require('../../assets/images/productDetail/coo/icon-1.png'),
						title:'检测精准',
						text:'检测30个特征基因的表达水品，与金标准比较一致性达92%。'
					},{
						img:require('../../assets/images/productDetail/coo/icon-2.png'),
						title:'客观快速',
						text:'人工智能分析软件，自动数据分析，结果不受主观判断影响；3-5个工作日完成检测，并出具报告。'
					}],
					people:['弥漫性大B淋巴瘤患者','需要精准治疗的DLBCL患者','临床无法提供全面DLBCL分子分型的患者']
				},
			}
		},
		mounted() {
			var that = this;
			that.show = true;
		}
	}
</script>

<style lang="scss" scoped>
	.myProduct{
		.productMain {
			padding: 2% 15% 5% !important;
			display: flex;
		}
		.leftDetail{
			width: 200px;
			flex-shrink: 0;
			margin-right: 20px;
		}
		.rightDetail{
			width: 100%;
		}
	}
	@media (max-width:1024px){
		.leftDetail{
			display:none !important;
		}
	}
</style>